import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { Flex } from "antd";
import { useDispatch } from "react-redux";
import { getTeamMembers } from "API/members";
import { setError } from "redux/workspaceSlice";
import MemberCard from "./MemberCard";
import LoadingScreen from "layouts/LoadingScreen";
import { Card} from "react-bootstrap";

const Members = () => {
  const [membersList, setMembersList] = useState([]);
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    getMembersData();
  }, []);

  const getMembersData = async () => {
    setIsLoader(true);
    await getTeamMembers()
      .then((res) => {
        console.log(res, res.data, "team members response");
        if (res?.data.error) {
          setError({
            isShow: true,
            title: "Error",
            message: res?.data?.error,
          });
          return;
        }
        if (res?.data?.list) {
          setMembersList(res.data.list);
        }
      })
      .catch((err) => {
        if (err.response.status) {
          console.log(err, err.response.status, "add-workspace-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.message || err.response.data.error,
            })
          );
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  if (isLoader) {
    return <LoadingScreen />;
  }
  // console.log(membersList, "membersList");
  return (
    <Flex vertical>
      <h4>My Team</h4>
      {membersList.length > 0 ? (
        <div className={styles.membersWrapper}>
          {membersList.map((member) => (
            <MemberCard member={member} key={member.participant_id} />
          ))}
        </div>
      ) : (
        <Card>
          <Card.Body>
            <div className="message">There are no members in your team yet</div>
          </Card.Body>
        </Card>
      )}
    </Flex>
  );
};

export default Members;
