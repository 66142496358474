const DescLoadingShimmer = () => {
  return (
    <div>
      <div className="animated-background mx-2 my-1"></div>
      <div className="animated-background mx-2 my-1"></div>
      <div className="animated-background mx-2 my-1 w-50"></div>
    </div>
  );
};

export default DescLoadingShimmer;
