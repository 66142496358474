import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { App as AntDesignApp, Flex, Table, Form, Button, DatePicker, Spin } from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";
import { formatDate } from "services/helpers";
import AddSpecialDaysForm from "features/calendar/AddSpecialDaysForm";
import { useConfirmModal } from "hooks/useConfirmDelete";
import { deleteSpecialDay } from "API/calendar";
import { toast } from "react-toastify";
import { useWarningModal } from "hooks/useWarningModal";
import * as dayjs from "dayjs";
import "dayjs/locale/en";
import localeData from "dayjs/plugin/localeData";
import { useCalendarAPI } from "features/calendar/hooks/useCalendarAPI";

dayjs.extend(localeData);
const { RangePicker } = DatePicker;

const SpecialTable = ({ isAdmin, setSpecialDays }) => {
  const { modal } = AntDesignApp.useApp();
  const [form] = Form.useForm();
  const { getCalendarData, loading } = useCalendarAPI();

  const { onConfirmDelete } = useConfirmModal();
  const { onDisplayError } = useWarningModal();

  const [tableSpecialDays, setTableSpecialDays] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const startDate = dayjs();
      const endDate = startDate.add(1, "year");

      form.setFieldsValue({
        dateRange: [startDate, endDate]
      });

      const res = await getCalendarData(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"));
      setTableSpecialDays(res?.special);
      setCountries(res?.countries);
    };

    fetchData();
  }, [form]);

  const columns = [
    {
      title: "Special day",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 200
    },
    {
      title: "Date start",
      key: "date_start",
      render: (_, record) => (record.date_start ? `${formatDate(record.date_start)}` : " - "),
      width: 100
    },
    {
      title: "Date end",
      key: "date_end",
      render: (_, record) => (record.date_end ? `${formatDate(record.date_end)}` : " - "),
      width: 100
    },
    {
      title: "Country",
      dataIndex: "country_name",
      key: "country_name",
      // render: (_, record) => (record.date_end ? `${formatDate(record.date_end)}` : " - "),
      width: 180
    },
    isAdmin && {
      title: "",
      key: "edit",
      width: 20,
      render: (_, record) => (record._id ? <FaEdit className="tw-cursor-pointer" onClick={() => editSpecialDay(record)} /> : null)
    },
    isAdmin && {
      title: "",
      key: "deletion",
      width: 20,
      render: (_, record) =>
        record._id ? (
          <FaTrash
            color="red"
            className="tw-cursor-pointer"
            onClick={() => onConfirmDelete(`${record.name} special day`, () => deleteSpecial(record._id))}
          />
        ) : null
    }
  ].filter(Boolean);

  const editSpecialDay = (item) => {
    const modalInstance = modal.confirm({
      title: <Flex className="">Edit special day</Flex>,
      icon: null,
      content: (
        <Flex>
          <AddSpecialDaysForm
            onClose={() => modalInstance.destroy()}
            countries={countries}
            specialDayItem={item}
            setTableSpecialDays={setTableSpecialDays}
            setSpecialDays={setSpecialDays}
          />
        </Flex>
      ),
      width: 600,
      footer: null,
      closable: true
    });
  };

  const deleteSpecial = async (id) => {
    console.log(id, "delete-Special Days");
    await deleteSpecialDay({ _id: id })
      .then((res) => {
        console.log(res, res.data, "get-docs");
        if (res?.data?.error) {
          onDisplayError(res.data.error);
          return;
        }
        if (res.data.success === 0) {
          toast.error(res.data.message, {
            theme: "colored"
          });
        }
        if (res.data.success === 1) {
          toast.success("This special day has been successfully delited", {
            theme: "colored"
          });
          const special = res.data.result.special;
          setSpecialDays(special);
          setTableSpecialDays(special);
        }
      })
      .catch((err) => {
        console.log(err, "AddSpecialDays -err");
        if (err?.response?.status === 401) {
          window.location.href = "https://account2.task925.com/?killsession";
        } else onDisplayError(err.message);
      });
  };

  const onFinish = async (values) => {
    console.log(values, "values-DatesFinish");
    const date_start = values.dateRange ? formatDate(values.dateRange[0]) : null;
    const date_end = values.dateRange ? formatDate(values.dateRange[1]) : null;

    const res = await getCalendarData(date_start, date_end);
    setTableSpecialDays(res?.special);
  };

  
  if (loading) {
    return (
      <Flex justify="center" align="center" className="tw-w-full tw-h-20">
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <Flex vertical className="tw-w-full">
      <Flex justify="space-between" className="tw-w-full">
        <Form form={form} onFinish={onFinish} className="tw-w-full">
          <Flex justify="end" className="tw-w-full">
            <Flex className="!tw-w-2/3" gap={8}>
              <Form.Item label="Dates filter" name="dateRange" className="special-table-range !tw-w-full">
                <RangePicker className="tw-w-full" locale={{ lang: { locale: "en" } }} allowEmpty={[true, true]} />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" className="w-full btn-primary">
                  Submit
                </Button>
              </Form.Item>
            </Flex>
          </Flex>
        </Form>
      </Flex>
      <Table
        columns={columns}
        dataSource={[...tableSpecialDays].map((item) => ({ ...item, key: item._id }))}
        scroll={{ x: "max-content" }}
        pagination={false}
        components={{
          header: {
            cell: (props) => <th {...props} style={{ padding: "4px 8px" }} />
          },
          body: {
            cell: (props) => <td {...props} style={{ padding: "6px 8px" }} />
          }
        }}
        className="tw-w-full"
      />
    </Flex>
  );
};
export default SpecialTable;

SpecialTable.propTypes = {
  specialDays: PropTypes.array,
  isAdmin: PropTypes.bool,
  countries: PropTypes.array,
  setSpecialDays: PropTypes.func
};
