import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Flex, Form, Input, Select, Button, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { addLeaveRequest, editLeaveRequest } from "API/calendar";
import { toast } from "react-toastify";
import { useWarningModal } from "hooks/useWarningModal";
import { formatDateForServer } from "services/helpers";
import * as dayjs from "dayjs";
import "dayjs/locale/en";
import localeData from "dayjs/plugin/localeData";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
dayjs.extend(localeData);

const AddLeaveDaysForm = ({
  adminsOptions,
  leaveTypes,
  onClose,
  onCloseEventModal,
  leaveDayItem = null,
  updateCalendarData = null,
  updateBigCalendarData = null,
  isAdmin,
  workspaceId
}) => {
  const { accountISbb_accountsID } = useSelector((state) => state.appsetting.account);
  const { onDisplayError } = useWarningModal();
  const [form] = Form.useForm();

  const [statuses, setStatuses] = useState([]);
  const statusesOptions = ["pending", "approved", "rejected"];

  useEffect(() => {
    // console.log(leaveDayItem, 'leaveDayItem')
    if (leaveDayItem) {
      const { date_start, date_end, textISsmallplaintextbox, reasonID, statuses: _statuses } = leaveDayItem;
      form.setFieldsValue({
        dateRange: [dayjs(date_start, "YYYY-MM-DD HH:mm:ss"), dayjs(date_end, "YYYY-MM-DD HH:mm:ss")],
        textISsmallplaintextbox,
        status: _statuses.find((item) => item.admin === accountISbb_accountsID)?.status || "pending",
        reasonID
      });
      setStatuses(_statuses);
      if (_statuses && _statuses?.length > 0) {
        const selectedAdmins = _statuses.map((item) => item.admin);

        form.setFieldsValue({
          admins: selectedAdmins
        });
      }
    } else {
      form.setFieldsValue({ status: "pending" });
    }
  }, [leaveDayItem, form, adminsOptions]);

  const onFinish = async (values) => {
    // console.log(values, values.admins, "values-AddLeaveDays");
    const formData = {
      accountISbb_accountsID,
      workspace_id: workspaceId,
      admins: values?.admins,
      date_start: formatDateForServer(values?.dateRange[0]),
      date_end: formatDateForServer(values?.dateRange[1]),
      textISsmallplaintextbox: values?.textISsmallplaintextbox,
      status: values?.status,
      reasonID: values?.reasonID
    };
    const request = leaveDayItem ? editLeaveRequest({ ...formData, _id: leaveDayItem._id }) : addLeaveRequest(formData);
    await request
      .then((res) => {
        if (res?.data?.error) {
          onDisplayError(res.data.error);
          return;
        }
        if (res.data.success === 0) {
          toast.error(res.data.message, {
            theme: "colored"
          });
        }
        if (res.data.success === 1) {
          toast.success(res.data.message, {
            theme: "colored"
          });
          updateCalendarData && updateCalendarData();
          updateBigCalendarData && updateBigCalendarData();
          onClose();
          onCloseEventModal && onCloseEventModal();
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.location.href = "https://account2.task925.com/?killsession";
        } else onDisplayError(err?.message || err?.data?.error);
      });
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} className="tw-w-full">
      <Flex className="tw-w-full" gap={8}>
        <Form.Item label="Admins" name="admins" className="tw-w-full" rules={[{ required: true, message: "Please select admins!" }]}>
          <Select
            mode="multiple"
            placeholder="Select admins"
            options={adminsOptions.map((admin) => ({
              label: admin.label,
              value: admin.value
            }))}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="Dates"
          name="dateRange"
          className="!tw-w-full"
          rules={[{ required: true, message: "Please select a date range!" }]}
        >
          <RangePicker className="tw-w-full" locale={{ lang: { locale: "en" } }} allowEmpty={[true, true]} />
        </Form.Item>
      </Flex>

      <Form.Item label="Description" name="textISsmallplaintextbox">
        <TextArea placeholder="Enter your text..." rows={4} />
      </Form.Item>

      <Flex className="tw-w-full" gap={8}>
        <Form.Item label="Statuses" name="status" className="tw-w-full" rules={[{ required: true, message: "Please select a status!" }]}>
          <Select style={{ width: "100%" }} placeholder="Select status" disabled={!isAdmin && !leaveDayItem}>
            {statusesOptions.map((status) => (
              <Select.Option key={status} value={status}>
                {status}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Reasons" name="reasonID" className="tw-w-full" rules={[{ required: true, message: "Please select a reason!" }]}>
          <Select style={{ width: "100%" }} placeholder="Select reason">
            {leaveTypes.map((type) => (
              <Option key={type._id} value={type._id}>
                {type?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>
      <Flex vertical className="tw-w-[50%]">
        {statuses.map((item) => (
          <Flex key={item.admin} justify="space-between">
            <span className="tw-text-lg">{item.name}</span> <span className="tw-text-base">{item.status}</span>
          </Flex>
        ))}
      </Flex>
      <Flex justify="end" className="tw-w-full">
        <Form.Item>
          <Button htmlType="submit" className="w-full btn-primary">
            Submit
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
};

AddLeaveDaysForm.propTypes = {
  adminsOptions: PropTypes.array,
  leaveTypes: PropTypes.array,
  onClose: PropTypes.func,
  onCloseEventModal: PropTypes.func,
  leaveDayItem: PropTypes.object,
  updateCalendarData: PropTypes.func,
  updateBigCalendarData: PropTypes.func,
  isAdmin: PropTypes.bool,
  workspaceId: PropTypes.string
};
export default AddLeaveDaysForm;
