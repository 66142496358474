import { App as AntDesignApp, Flex, Button } from "antd";
import { useDispatch } from "react-redux";
import { deleteTask } from "API/tasks";
import { setIsUpdateProject } from "redux/projectSlice";
import { toast } from "react-toastify";
import { useWarningModal } from "hooks/useWarningModal";

export const useModalActions = (onClose) => {
  const { modal } = AntDesignApp.useApp();
  const dispatch = useDispatch();
  const { onDisplayError } = useWarningModal();

  const onCloseWithoutSaving = () => {
    const modalInstance = modal.confirm({
      title: <Flex className="tw-text-lg">Close without saving?</Flex>,
      icon: null,
      width: 300,
      closable: true,
      footer: (
        <Flex justify="center" gap={10}>
          <Button
            className="btn-success"
            onClick={() => {
              modalInstance.destroy();
              onClose?.();
              document.body.classList.add("overflow-auto");
              document.body.classList.remove("overflow-hidden");
            }}
          >
            Yes
          </Button>
          <Button onClick={() => modalInstance.destroy()} className="btn-primary">
            No
          </Button>
        </Flex>
      )
    });
  };
  // /task deletion
  const onDeleteClicked = async (taskId) => {
    await deleteTask({ task_id: taskId })
      .then((res) => {
        // console.log(res, res?.data, "delete-task-err-200");
        if (res?.data?.error) {
          onDisplayError(res?.data?.error);
          return;
        }
        if (res?.data?.success === 1) {
          toast.success(`Task TK${taskId} has been successfully deleted`, {
            theme: "colored"
          });
          dispatch(setIsUpdateProject(true));
        }
      })
      .catch((err) => {
        console.log(err, "err");
        if (err.response?.status) {
          onDisplayError(err.response.data.error);
        }
      })
      .finally(() => {
        onClose?.();
        document.body.classList.add("overflow-auto");
        document.body.classList.remove("overflow-hidden");
      });
  };

  const onDeleteTask = (taskId) => {
    const modalInstance = modal.confirm({
      title: <Flex className="tw-text-lg">Are you sure you want to delete this task?</Flex>,
      icon: null,
      width: 500,
      closable: true,
      footer: (
        <Flex justify="center" gap={10}>
          <Button
            className="btn-danger"
            onClick={() => {
              onDeleteClicked(taskId);
              modalInstance.destroy();
            }}
          >
            Yes
          </Button>
          <Button onClick={() => modalInstance.destroy()} className="btn-primary">
            No
          </Button>
        </Flex>
      )
    });
  };

  return { onCloseWithoutSaving, onDeleteTask };
};
