import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Flex, Form, Input, DatePicker, Select, Button } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/en";
import localeData from "dayjs/plugin/localeData";
import { toast } from "react-toastify";
import { formatDateForServer } from "services/helpers";
import { useWarningModal } from "hooks/useWarningModal";
import { addSpecaialDays, editSpecialDay } from "API/calendar";

dayjs.extend(localeData);
const { Option } = Select;
const { RangePicker } = DatePicker;

const AddSpecialDaysForm = ({ specialDayItem = null, onClose, countries, setSpecialDays, setTableSpecialDays, onCloseEventModal }) => {
  const { onDisplayError } = useWarningModal();

  const [form] = Form.useForm();

  useEffect(() => {
    console.log(specialDayItem, "leaveDayItem");
    if (specialDayItem) {
      const { name, date_start, date_end, countryISbb_t925_special_days_countryID } = specialDayItem;

      form.setFieldsValue({
        dateRange: [dayjs(date_start, "YYYY-MM-DD HH:mm:ss"), dayjs(date_end, "YYYY-MM-DD HH:mm:ss")],
        name,
        countryID: countryISbb_t925_special_days_countryID
      });
    }
  }, [specialDayItem, form]);

  const onFinish = async (values) => {
    const formData = {
      name: values.name,
      date_start: formatDateForServer(values.dateRange[0]),
      date_end: formatDateForServer(values.dateRange[1]),
      countryID: values.countryID
    };
    // console.log("Form values:", values, formData);
    const request = specialDayItem ? editSpecialDay({ ...formData, _id: specialDayItem._id }) : addSpecaialDays(formData);
    await request
      .then((res) => {
        console.log(res, res.data, "get-docs");
        if (res?.data?.error) {
          onDisplayError(res.data.error);
          return;
        }
        if (res.data.success === 0) {
          toast.error(res.data.message, {
            theme: "colored"
          });
        }
        if (res.data.success === 1) {
          toast.success(`This special day has been successfully ${specialDayItem ? 'edited' : 'added'}`, {
            theme: "colored"
          });
          const special = res.data.result.special;
          setSpecialDays(special);
          setTableSpecialDays && setTableSpecialDays(special);
          onClose();
          onCloseEventModal && onCloseEventModal();
        }
      })
      .catch((err) => {
        // console.log(err, "AddSpecialDays -err");
        if (err?.response?.status === 401) {
          window.location.href = "https://account2.task925.com/?killsession";
        } else onDisplayError(err.message);
      });
  };

  return (
    <Flex className="tw-w-full">
      <Form form={form} layout="vertical" onFinish={onFinish} className="tw-w-full">
        <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please enter your name!" }]}>
          <Input placeholder="Enter name" className="tw-w-full" />
        </Form.Item>

        <Flex className="!tw-w-full" gap={8}>
          <Form.Item
            label="Date Range"
            name="dateRange"
            className="!tw-w-full"
            rules={[{ required: true, message: "Please select a date range!" }]}
          >
            <RangePicker className="tw-w-full" locale={{ lang: { locale: "en" } }} allowEmpty={[true, true]} />
          </Form.Item>

          <Form.Item
            label="Country"
            name="countryID"
            className="!tw-w-full"
            rules={[{ required: true, message: "Please select a country!" }]}
          >
            <Select placeholder="Select country" className="w-full">
              {countries.map((country) => (
                <Option key={country._id} value={country._id}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Flex>
        <Flex justify="end" className="tw-w-full">
          <Form.Item>
            <Button htmlType="submit" className="w-full btn-primary">
              Submit
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Flex>
  );
};

export default AddSpecialDaysForm;

AddSpecialDaysForm.propTypes = {
  specialDayItem: PropTypes.object,
  onClose: PropTypes.func,
  updateCalendarData: PropTypes.func,
  countries: PropTypes.array,
  setSpecialDays: PropTypes.func,
  setTableSpecialDays: PropTypes.func,
  onCloseEventModal: PropTypes.func
};
