import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BigReactCalendar from "./BigReactCalendar";
import { App as AntDesignApp, Flex, Button } from "antd";
import { useSelector } from "react-redux";
import AddLeaveDaysForm from "features/calendar/AddLeaveDaysForm";
import AddSpecialDaysForm from "features/calendar/AddSpecialDaysForm";
import LeaveDays from "features/calendar/LeaveDays";
import SpecialTable from "features/calendar/SpecialTable";
import { getActiveLeaveDaysRequests } from "API/calendar";
import { useWarningModal } from "hooks/useWarningModal";
import { useCalendarAPI } from "features/calendar/hooks/useCalendarAPI";

const Calendar = ({ workspaceId }) => {
  const { modal } = AntDesignApp.useApp();
  const { onDisplayError } = useWarningModal();
  const { getCalendarData } = useCalendarAPI();

  const { accountISbb_accountsID } = useSelector((state) => state.appsetting.account);

  const [adminsOptions, setAdminsOptions] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [specialDays, setSpecialDays] = useState([]);
  const [countries, setCountries] = useState([]);

  const [approvedLeavesDays, setApprovedLeavesDays] = useState([]);

  useEffect(() => {
    fetchCalendarData();
    getActiveLievaDays();
  }, []);

  const fetchCalendarData = async () => {
    const res = await getCalendarData();
    setCountries(res?.countries);
    setSpecialDays(res?.special);
  };

  const getActiveLievaDays = async () => {
    await getActiveLeaveDaysRequests("approved", workspaceId)
      .then((res) => {
        // console.log(res, "res-active-leave-requests");
        if (res?.data?.error) {
          onDisplayError(res.data.error);
          return;
        }
        const result = res.data.result;
        const admins = res.data.admins;
        const reasons = res.data.reasons;
        if (result) {
          const onlyApprovedRequests = result.filter((item) => item.status === "approved");
          setApprovedLeavesDays(onlyApprovedRequests);
        }
        if (reasons) {
          setLeaveTypes(reasons);
        }
        if (admins) {
          const options = admins
            .filter((item) => item.account_id !== "272") // Hanna Tsibulya - was removed for the dev server
            .map((item) => {
              return { label: item.account_name, value: item.account_id };
            });
          setAdminsOptions(options);
          const ids = admins.map((item) => {
            return item.account_id;
          });
          const is_admin = ids.some((id) => id === accountISbb_accountsID);
          setIsAdmin(is_admin);
        }
      })
      .catch((err) => {
        console.log(err, "err-add-leave-days");
        if (err?.response?.status === 401) {
          window.location.href = "https://account2.task925.com/?killsession";
        } else onDisplayError(err.message);
      });
  };

  const addSpecialDays = () => {
    const modalInstance = modal.confirm({
      title: <Flex className="">Add special days</Flex>,
      icon: null,
      content: (
        <Flex>
          <AddSpecialDaysForm onClose={() => modalInstance.destroy()} setSpecialDays={setSpecialDays} countries={countries} />
        </Flex>
      ),
      width: 600,
      footer: null,
      closable: true
    });
  };

  const requestLeaveDays = () => {
    const modalInstance = modal.confirm({
      title: <Flex className="">Request Leave days</Flex>,
      icon: null,
      content: (
        <Flex>
          <AddLeaveDaysForm
            onClose={() => modalInstance.destroy()}
            adminsOptions={adminsOptions}
            leaveTypes={leaveTypes}
            workspaceId={workspaceId}
          />
        </Flex>
      ),
      width: 600,
      footer: null,
      closable: true
    });
  };

  const approveLeaveDays = () => {
    const modalInstance = modal.confirm({
      title: <Flex className="">Leave days</Flex>,
      icon: null,
      content: (
        <Flex>
          <LeaveDays updateBigCalendarData={getActiveLievaDays} workspaceId={workspaceId} />
        </Flex>
      ),
      width: 800,
      closable: true,
      footer: () => (
        <Button onClick={() => modalInstance.destroy()} type="default">
          Close
        </Button>
      )
    });
  };

  const openSpecialDaysTable = () => {
    const modalInstance = modal.confirm({
      title: <Flex className="">Special days</Flex>,
      icon: null,
      content: (
        <Flex>
          <SpecialTable isAdmin={isAdmin} setSpecialDays={setSpecialDays} />
        </Flex>
      ),
      width: 800,
      closable: true,
      footer: () => (
        <Button onClick={() => modalInstance.destroy()} type="default">
          Close
        </Button>
      )
    });
  };

  return (
    <Flex vertical justify="center" className="tw-bg-white tw-rounded-lg tw-p-6">
      <Flex justify="space-between" align="center" className="w-full">
        <Flex align="center" gap={16}>
          <h2>Calendar</h2>
          <Flex align="center" gap={8}>
            <Button onClick={approveLeaveDays} className="ocean-color-button">
              Leave days
            </Button>
            <Button onClick={openSpecialDaysTable} className="ocean-color-button">
              Special days
            </Button>
          </Flex>
        </Flex>
        <Flex gap={8}>
          {isAdmin && (
            <Button onClick={addSpecialDays} className="ocean-color-button">
              Add Special days
            </Button>
          )}
          <Button onClick={requestLeaveDays} className="ocean-color-button">
            Request Leave
          </Button>
        </Flex>
      </Flex>
      <BigReactCalendar
        leavesDays={approvedLeavesDays}
        specialDays={specialDays}
        isAdmin={isAdmin}
        adminsOptions={adminsOptions}
        leaveTypes={leaveTypes}
        countries={countries}
        setSpecialDays={setSpecialDays}
        workspaceId={workspaceId}
        updateBigCalendarData={getActiveLievaDays}
      />
    </Flex>
  );
};

export default Calendar;

Calendar.propTypes = {
  workspaceId: PropTypes.string
};
