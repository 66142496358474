import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.css";
import { /*App as AntDesignApp,*/ Flex } from "antd";

import { format, addDays, subDays } from "date-fns";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import Textarea from "components/Textarea";
import { FaCalendarPlus } from "react-icons/fa6";
import { Accordion } from "react-bootstrap";
import Modal from "components/ModalWindow";
import useModal from "hooks/useModal";
// import AddLeaveDaysForm from "features/dashboard/AddLeaveDaysForm";
import AddSpecialDaysForm from "./AddSpecialDaysForm";
import { formatDate } from "services/helpers";
// import { FaEdit  } from "react-icons/fa";
import { useCalendarAPI } from "features/calendar/hooks/useCalendarAPI";
import * as dayjs from "dayjs";

export default function Agenda() {
  // const { accountISbb_accountsID } = useSelector((state) => state.appsetting.account);
  // const { modal } = AntDesignApp.useApp();
  const { getCalendarData } = useCalendarAPI();

  const [isAddSpecialDaysModal, openAddSpecaialDaysModal, closeSpecialDaysModal] = useModal();
  const [date, setDate] = useState(new Date());
  const [text, setText] = useState("");
  // const [adminsOptions, setAdminsOptions] = useState([]);
  // const [adminsIds, setAdminsIds] = useState([]);

  const [leavesDays, setLeavesDays] = useState([]);
  const [specialDays, setSpecialDays] = useState([]);

  const textRef = useRef(null);

  useEffect(() => {
    const fetchCalendarData = async () => {
      const startDate = formatDate(dayjs());
      const endDate = formatDate(dayjs());

      const res = await getCalendarData(startDate, endDate);
      setLeavesDays(res.leaves);
      setSpecialDays(res?.special);
    };

    fetchCalendarData();
  }, []);

  const showNextDate = () => {
    const nextDate = addDays(date, 1);
    setDate(nextDate);
  };

  const showPreviousDate = () => {
    const previousDate = subDays(date, 1);
    setDate(previousDate);
  };
console.log(specialDays)
  return (
    <>
      <div className="tasks-card card" style={{ minWidth: "300px" }}>
        <div className="project-card-header">
          <div className="d-flex justify-content-between tasks-card-title project-card-title">
            <div className="prev nav-item" onClick={showPreviousDate}>
              <BiLeftArrow />
            </div>
            <span>{format(date, "MMM dd, eee")}</span>
            <div className="next nav-item" onClick={showNextDate}>
              <BiRightArrow />
            </div>
          </div>
        </div>

        {/* <div className="agenda-card-body"> */}
        <div className="mt-2">
          <Textarea
            textareaRef={textRef}
            placeholder="Enter your text..."
            rows={4}
            value={text}
            onChange={(e) => setText(e.target.value)}
            // isDisable={isDisable}
          />
          <Accordion defaultActiveKey="0" className="my-2">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {/* <FaCalendarPlus
                  className="me-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    requestLeaveDays();
                  }}
                /> */}
                Leave days
              </Accordion.Header>
              <Accordion.Body>
                {leavesDays.length > 0 &&
                  leavesDays.map((item) => (
                    <Flex key={item._id} justify="space-between" className="tw-w-full tw-mb-2" gap={8}>
                      <span className="tw-font-bold">{item.user_name}</span>
                      <span>{formatDate(item.date_start)}</span> - <span>{formatDate(item.date_end)}</span>
                      <span>{item.reason_name}</span>
                    </Flex>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <FaCalendarPlus
                  className="me-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    openAddSpecaialDaysModal(true);
                  }}
                />
                Special days
              </Accordion.Header>
              <Accordion.Body>
              {specialDays.length > 0 &&
                  specialDays.map((item) => (
                    <Flex key={item._id} justify="space-between" className="tw-w-full tw-mb-2" gap={8}>
                      <span className="tw-font-bold">{item.name}</span>
                      <span>{formatDate(item.date_start)}</span> - <span>{formatDate(item.date_end)}</span>
                      <span>{item.country_name}</span>
                    </Flex>
                  ))}

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <Modal
        isShow={isAddSpecialDaysModal}
        modalHeader="Add Special Days"
        handleClose={() => {
          closeSpecialDaysModal();
          document.body.classList.remove("overflow-hidden");
          document.body.classList.add("overflow-auto");
        }}
        headerStyles={{ padding: "24px" }}
        bodyStyles={{ padding: "24px", height: "500px" }}
      >
        <AddSpecialDaysForm onClose={closeSpecialDaysModal} />
      </Modal>
    </>
  );
}
