import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { useSelector, useDispatch } from "react-redux";
import ActionerTodayBlock from "./ActionerTodayBlock";
import Agenda from "./Agenda";
import LoadingScreen from "layouts/LoadingScreen";
import TodayTimeline from "./TodayTimeline";
import { getTeamMemberDetails } from "API/members";
import { sortByField } from "services/helpers";
import { setIsUpdateProject } from "redux/projectSlice";
// import { getViewReport } from "API/reports";
import { getDashboardData } from "API/dashboard";
import { useWarningModal } from "hooks/useWarningModal";

const Dashboard = () => {
  const { accountISbb_accountsID: accountID } = useSelector((state) => state.appsetting.account);
  const dispatch = useDispatch();
  const { onDisplayError } = useWarningModal();

  const [todayTasks, setTodayTasks] = useState([]);
  const [isActionerResults, setIsActionersResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionerTodayList, setActionerTodayList] = useState({});

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        await getAsActionerTodayData(accountID);
        await ViewFilters();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      dispatch(setIsUpdateProject(false));
    };
  }, []);

  const getAsActionerTodayData = async (accountId) => {
    await getTeamMemberDetails(accountId)
      .then((res) => {
        console.log(res, "res-getTeamMemeber");
        if (res?.data?.error) {
          onDisplayError(res?.data?.error);
          return;
        }
        const teamMember = res?.data?.team_member;
        setTodayTasks(sortByField(teamMember?.tasks, "actioner_priorityNUM"));
      })
      .catch((err) => {
        if (err?.response?.status) {
          const message = err.message || err.response.data.error;
          onDisplayError(message);
        }
      });
  };

  const ViewFilters = async () => {
    setIsLoading(true);
    try {
      // await getViewReport({
      //   closedYN: "0",
      //   group_by_actionerYN: "1",
      // })
      await getDashboardData()
        .then((res) => {
          console.log(res, "getDashboard");
          if (res?.data?.error) {
            onDisplayError(res?.data?.error);
            return;
          }
          if (res.data.success === 1) {
            // console.log(res.data.list, "res.data-Dashboard ViewReport");
            setActionerTodayList(Object.values(res?.data?.list));
            setIsActionersResults(Boolean(res?.data?.results));
          }
        })
        .catch((err) => {
          onDisplayError(err?.response?.data?.error);
        });
    } catch (err) {
      onDisplayError(err.toString());
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="container content-wrapper">
      <div className="dashboard-wrapper">
        <div className="dashboard-left">
          <TodayTimeline todayTasks={todayTasks} isActioner={isActionerResults} />
        </div>
        <div className="dashboard-center-right">
          {isActionerResults && (
            <div className="dashboard-center">
              <ActionerTodayBlock actionerTodayList={actionerTodayList} />
            </div>
          )}
          <div className="dashboard-right">
            <Agenda />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
