import { App as AntDesignApp, Flex, Button } from "antd";
import { IoIosWarning } from "react-icons/io";

export const useWarningModal = () => {
  const { modal } = AntDesignApp.useApp();

  const onDisplayWarning = (message) => {
    const modalInstance = modal.confirm({
      title: (
        <Flex align="center" className="tw-text-lg" gap={8}>
          <IoIosWarning color="#faad14" size={24} />
          <span className="tw-text-xl">Warning!</span>
        </Flex>
      ),
      content: <span className="tw-text-lg">{message}</span>,
      icon: null,
      width: 600,
      closable: true,
      footer: (
        <Flex justify="center" className="tw-mt-6">
          <Button onClick={() => modalInstance.destroy()} className="btn-primary">
            Close
          </Button>
        </Flex>
      )
    });
  };

  const onDisplayError = (message) => {
    const modalInstance = modal.confirm({
      title: (
        <Flex align="center" className="tw-text-lg" gap={8}>
          <IoIosWarning color="#c82333" size={24} />
          <span className="tw-text-xl">Error!</span>
        </Flex>
      ),
      content: (
        <Flex justify="center" className="tw-w-full">
          <span className="tw-text-lg">{message}</span>
        </Flex>
      ),
      icon: null,
      width: 400,
      closable: true,
      footer: (
        <Flex justify="center" className="tw-mt-6">
          <Button onClick={() => modalInstance.destroy()} className="btn-primary">
            Close
          </Button>
        </Flex>
      )
    });
  };

  return { onDisplayWarning, onDisplayError };
};
