import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { App as AntDesignApp, Flex } from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { formatDateForServer } from "services/helpers";
import AddLeaveDaysForm from "features/calendar/AddLeaveDaysForm";
import AddSpecialDaysForm from "features/calendar/AddSpecialDaysForm";
import { deleteSpecialDay } from "API/calendar";
import { toast } from "react-toastify";
import { useWarningModal } from "hooks/useWarningModal";
import { useConfirmModal } from "hooks/useConfirmDelete";

const localizer = momentLocalizer(moment);

const BigReactCalendar = ({
  leavesDays,
  specialDays,
  isAdmin,
  countries,
  adminsOptions,
  leaveTypes,
  setSpecialDays,
  workspaceId,
  updateBigCalendarData
}) => {
  // console.log(specialDays, "specialDays");
  const { modal } = AntDesignApp.useApp();
  const { onConfirmDelete } = useConfirmModal();
  const { onDisplayError } = useWarningModal();

  const [events, setEvents] = useState([]);

  const transformEvents = (data) => {
    return data.map((item) => ({
      title: `${item.user_name}'s Vacation - ${item.reason_name}`,
      start: new Date(item.date_start),
      end: new Date(new Date(item.date_end).setDate(new Date(item.date_end).getDate() + 1)),
      reason: item.reason_name,
      description: item.textISsmallplaintextbox || "No description",
      _id: item._id,
      date_start: item.date_start,
      date_end: item.date_end,
      textISsmallplaintextbox: item.textISsmallplaintextbox,
      statuses: item.statuses,
      reasonID: item.reasonID,
      type: "leave"
    }));
  };

  const transformSpecialDays = (data) => {
    return data.map((item) => ({
      _id: item._id,
      title: `${item.country_name}: ${item.name}`,
      name: item.name,
      countryISbb_t925_special_days_countryID: item.countryISbb_t925_special_days_countryID,
      date_start: item.date_start,
      date_end: item.date_end,
      start: new Date(item.date_start),
      end: new Date(new Date(item.date_end).setDate(new Date(item.date_end).getDate() + 1)),
      description: `Holiday in ${item.country_name}`,
      type: "special"
    }));
  };

  useEffect(() => {
    const leaveEvents = leavesDays ? transformEvents(leavesDays) : [];
    const specialEvents = specialDays ? transformSpecialDays(specialDays) : [];
    setEvents([...leaveEvents, ...specialEvents]);
  }, [leavesDays, specialDays]);

  const dayPropGetter = (date) => {
    const isWeekend = moment(date).day() === 0 || moment(date).day() === 6;
    const isSpecialDay = specialDays?.some(
      (day) => moment(date).isSame(moment(day.date_start), "day") || moment(date).isSame(moment(day.date_end), "day")
    );

    if (isWeekend) {
      return { style: { backgroundColor: "#ffe6e6" } };
    }

    if (isSpecialDay) {
      return { style: { backgroundColor: "#d4edda" } };
    }

    return {};
  };

  const eventPropGetter = (event) => {
    return {
      style: {
        backgroundColor: event.type === "leave" ? "#3174ad" : "#4CAF50",
        color: "white"
      }
    };
  };

  const openEventModal = (event) => {
    const modalInstance = modal.confirm({
      title:
        event.type === "special" ? (
          <Flex className="tw-text-xl">Congratulations 🎉🎉🎉!!!</Flex>
        ) : (
          <Flex className="tw-text-xl">{event.title}</Flex>
        ),
      icon: null,
      content:
        event.type === "special" ? (
          <Flex vertical className="tw-text-lg">
            <Flex justify="space-between">
              <Flex gap={8}>
                <span className="tw-font-semibold">Event:</span>
                <span> {event.name}</span>
              </Flex>
              {isAdmin && (
                <Flex gap={8}>
                  <FaEdit className="tw-cursor-pointer" onClick={() => editSpecialDay(event, () => modalInstance.destroy())} />
                  <FaTrash
                    color="red"
                    className="tw-cursor-pointer"
                    onClick={() =>
                      onConfirmDelete(`${event.name} special day`, () => deleteSpecial(event._id, () => modalInstance.destroy()))
                    }
                  />
                </Flex>
              )}
            </Flex>
            <Flex gap={8}>
              <span className="tw-font-semibold">Description:</span> <span>{event.description}</span>
            </Flex>
          </Flex>
        ) : (
          <Flex vertical>
            <Flex justify="space-between">
              <Flex gap={8}>
                <span className="tw-font-semibold">Date start:</span>
                <span> {formatDateForServer(event.start)}</span>
              </Flex>
              {isAdmin && (
                <Flex gap={8}>
                  <FaEdit className="tw-cursor-pointer" onClick={() => editLeaveDay(event, () => modalInstance.destroy())} />
                </Flex>
              )}
            </Flex>
            <Flex gap={8}>
              <span className="tw-font-semibold">Date end:</span>
              <span> {formatDateForServer(event.end)}</span>
            </Flex>
            <Flex gap={8}>
              <span className="tw-font-semibold">Reason:</span>
              <span> {event.reason}</span>
            </Flex>
            <Flex gap={8}>
              <span className="tw-font-semibold">Description:</span>
              <span> {event.description}</span>
            </Flex>
          </Flex>
        ),
      width: 600,
      footer: null,
      closable: true
    });
  };

  const editLeaveDay = (item, onCloseEventModal) => {
    const modalInstance = modal.confirm({
      title: <Flex className="">Edit leave day</Flex>,
      icon: null,
      content: (
        <Flex>
          <AddLeaveDaysForm
            onClose={() => modalInstance.destroy()}
            onCloseEventModal={onCloseEventModal}
            leaveDayItem={item}
            adminsOptions={adminsOptions}
            leaveTypes={leaveTypes}
            workspaceId={workspaceId}
            updateBigCalendarData={updateBigCalendarData}
          />
        </Flex>
      ),
      width: 600,
      footer: null,
      closable: true
    });
  };

  const editSpecialDay = (item, onCloseEventModal) => {
    const modalInstance = modal.confirm({
      title: <Flex className="">Edit special day</Flex>,
      icon: null,
      content: (
        <Flex>
          <AddSpecialDaysForm
            onClose={() => modalInstance.destroy()}
            onCloseEventModal={onCloseEventModal}
            countries={countries}
            specialDayItem={item}
            setSpecialDays={setSpecialDays}
          />
        </Flex>
      ),
      width: 600,
      footer: null,
      closable: true
    });
  };

  const deleteSpecial = async (id, onClose) => {
    console.log(id, "delete-Special Days");
    await deleteSpecialDay({ _id: id })
      .then((res) => {
        console.log(res, res.data, "get-docs");
        if (res?.data?.error) {
          onDisplayError(res.data.error);
          return;
        }
        if (res.data.success === 0) {
          toast.error(res.data.message, {
            theme: "colored"
          });
        }
        if (res.data.success === 1) {
          toast.success("This special day has been successfully delited", {
            theme: "colored"
          });
          const special = res.data.result.special;
          setSpecialDays(special);
          onClose();
        }
      })
      .catch((err) => {
        console.log(err, "AddSpecialDays -err");
        if (err?.response?.status === 401) {
          window.location.href = "https://account2.task925.com/?killsession";
        } else onDisplayError(err.message);
      });
  };
  return (
    <div style={{ height: "100vh" }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100%" }}
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventPropGetter}
        onSelectEvent={(event) => openEventModal(event)}
      />
    </div>
  );
};

export default BigReactCalendar;

BigReactCalendar.propTypes = {
  leavesDays: PropTypes.array,
  specialDays: PropTypes.array,
  isAdmin: PropTypes.bool,
  adminsOptions: PropTypes.array,
  leaveTypes: PropTypes.array,
  countries: PropTypes.array,
  setSpecialDays: PropTypes.func,
  workspaceId: PropTypes.string,
  updateBigCalendarData: PropTypes.func
};
