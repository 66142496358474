import React, { useState } from "react";
import styles from "./Workspace.module.scss";
import { useSelector, useDispatch } from "react-redux";
import WorkspaceSingle from "./WorkspaceSingle";
import WorkspaceDetail from "./WorkspaceDetail";
import {
  WORKSPACE_MENUITEMS,
  PROJECT_MENUITEMS,
  TASK_MENUITEMS,
} from "services/constants";
import {
  setWorkspaceProjects,
  fetchWorkspaces,
  setDisplayWorkspace,
  setError,
  setDisplayProject,
} from "redux/workspaceSlice";
import ProjectDetail from "../project/ProjectDetail";
import { HambugurMenuContext } from "services/contexts";
import TaskDetail from "features/task/TaskDetail";
import SubTaskDetail from "features/task/SubTaskDetail";
import NoWorkspace from "./NoWorkspace";
import { deleteWorkspace } from "API/workspaces";
import { deleteProject } from "API/projects";
import { setProjectsList } from "redux/projectSlice";
import ConfirmModal from "components/ConfirmModal";
import useGetWorkspaceData from "hooks/useGetWorkspaceData";
import {
  dispatchIsShowTaskDetail,
  dispatchIsShowSubTaskDetail,
} from "redux/taskSlice";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { useModalActions } from "features/task/hooks/useModalActions";

export default function WorkspaceList() {
  const workspaces = useSelector((state) => state.workspaces.workspaces);
  const projects = useSelector((state) => state.workspaces.projects);
  const projectsList = useSelector((state) => state.project.projectsList);
  const {
    isShowTaskDetail,
    openTaskId,
    isShowSubTaskDetail,
    openSubTaskId,
    openTaskLoading,
  } = useSelector((state) => state.tasks);
  const { project, isUpdateProject } = useSelector((state) => state.project);
  const { onDeleteTask } = useModalActions();

  const dispatch = useDispatch();
  const { fetchWorkspaceData } = useGetWorkspaceData();

  const [showWorkspace, setShowWorkspace] = useState(false);
  const [workspaceId, setWorkspaceId] = useState(null);

  const [showProjectDetail, setShowProjectDetail] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const [showTaskDetail, setShowTaskDetail] = useState(false);
  const [taskId, setTaskId] = useState(null);

  const [showSubTaskDetail, setShowSubTaskDetail] = useState(false);
  const [subTaskId, setSubTaskId] = useState(null);
  const [isDeleteWorkspaceConfirm, setIsDeleteWorkspaceConfirm] =
    useState(false);
  const [isDeleteProjectConfirm, setIsDeleteProjectConfirm] = useState(false);
  const [isItems, setIsItems] = useState(false);
  const [showCompleted, setShowCompleted] = useState(null);

  const onHamburgurMenuSelected = (
    menuLabel,
    id,
    parentId,
    isExistedElements = [],
    onAction = null
  ) => {
    if (menuLabel === WORKSPACE_MENUITEMS[0].label) {
      setShowWorkspace(true);
      setWorkspaceId(id);
      document.body.classList.remove("overflow-auto");
      document.body.classList.add("overflow-hidden");
    }
    // delete workspace
    else if (menuLabel === WORKSPACE_MENUITEMS[1].label) {
      setIsDeleteWorkspaceConfirm(true);
      setWorkspaceId(id);
      setIsItems(isExistedElements);
      document.body.classList.remove("overflow-auto");
    } else if (menuLabel === "New Project") {
      setShowProjectDetail(true);
      setWorkspaceId(id);
      setProjectId(null);
      document.body.classList.remove("overflow-auto");
      document.body.classList.add("overflow-hidden");
      // edit project
    } else if (menuLabel === PROJECT_MENUITEMS[0].label) {
      setShowProjectDetail(true);
      setProjectId(id);
      document.body.classList.remove("overflow-auto");
      document.body.classList.add("overflow-hidden");
    } else if (menuLabel === PROJECT_MENUITEMS[1].label) {
      setIsDeleteProjectConfirm(true);
      setProjectId(id);
      setIsItems(isExistedElements);
      setShowCompleted(onAction);
      document.body.classList.remove("overflow-auto");
    } else if (menuLabel === "New Task") {
      setShowTaskDetail(true);
      setProjectId(id);
      setTaskId(null);
      document.body.classList.remove("overflow-auto");
      document.body.classList.add("overflow-hidden");
    } else if (menuLabel === "Edit Task") {
      setShowTaskDetail(true);
      setTaskId(id);
      setProjectId(parentId);
      document.body.classList.remove("overflow-auto");
      document.body.classList.add("overflow-hidden");
    } else if (menuLabel === TASK_MENUITEMS[0].label) {
      onDeleteTask(id);
    } else if (menuLabel === TASK_MENUITEMS[1].label) {
      setShowSubTaskDetail(true);
      setTaskId(id);
      setSubTaskId(null);
      document.body.classList.remove("overflow-auto");
      document.body.classList.add("overflow-hidden");
    } else if (menuLabel === "Edit Sub-task") {
      setShowSubTaskDetail(true);
      setTaskId(parentId);
      setSubTaskId(id);
      document.body.classList.remove("overflow-auto");
      document.body.classList.add("overflow-hidden");
    }
  };

  const deleteWorkspaceData = async (workspace_id) => {
    await deleteWorkspace({ workspace_id: workspace_id })
      .then((res) => {
        console.log(res.data, "delete-worspace");
        if (res.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error,
            })
          );
          return;
        }
        const newWorkspaces = workspaces.filter(
          (workspace) =>
            workspace._id !== res?.data?.deleted_workspace_id.toString()
        );
        fetchWorkspaceData(newWorkspaces[0]._id);
        dispatch(fetchWorkspaces(newWorkspaces));
        dispatch(
          setDisplayWorkspace({ display_workspace: newWorkspaces[0]._id })
        );
      })
      .catch((err) => {
        if (err.response.status) {
          console.log(err, err.response.status, "delete-workspace-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.response.data.error,
            })
          );
        }
      })
      .finally(() => {
        setIsDeleteWorkspaceConfirm(false);
        document.body.classList.add("overflow-auto");
        document.body.classList.remove("overflow-hidden");
      });
  };

  const deleteProjectData = async (projectId) => {
    await deleteProject({ project_id: projectId })
      .then((res) => {
        console.log(res.data, "delete-project");
        if (res.data.error) {
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: res.data.error,
            })
          );
          return;
        }
        const newProjects = projects.filter(
          (project) => project._id !== projectId
        );
        dispatch(setWorkspaceProjects(newProjects));
        dispatch(
          setDisplayProject({
            display_project: newProjects[newProjects.length - 1]._id,
          })
        );
        dispatch(
          setProjectsList(projectsList.filter((item) => item._id !== projectId))
        );
      })
      .catch((err) => {
        if (err?.response?.status) {
          console.log(err, err?.response?.status, "delete-project-err");
          dispatch(
            setError({
              isShow: true,
              title: "Error",
              message: err.response.data.error,
            })
          );
        }
      })
      .finally(() => {
        setIsDeleteProjectConfirm(false);
        document.body.classList.add("overflow-auto");
        document.body.classList.remove("overflow-hidden");
      });
  };

  return (
    <>
      <CircleSpinnerOverlay
        loading={openTaskLoading}
        message="Loading..."
        color="#5e6e82"
        overlayColor="rgba(0,0,0,0.3)"
      />
      <div>
        <HambugurMenuContext.Provider value={onHamburgurMenuSelected}>
          <div className={styles.workspaceSingle}>
            <WorkspaceSingle />
          </div>
          {workspaces.length < 1 && (
            <div className="container content-wrapper">
              <NoWorkspace />
            </div>
          )}
        </HambugurMenuContext.Provider>

        {showWorkspace && (
          <WorkspaceDetail
            onClose={() => setShowWorkspace(false)}
            id={workspaceId}
          />
        )}
        {showProjectDetail && (
          <ProjectDetail
            onClose={() => setShowProjectDetail(false)}
            workspaceId={workspaceId}
            id={projectId}
          />
        )}
        {showTaskDetail && (
          <TaskDetail
            onClose={() => setShowTaskDetail(false)}
            projectId={projectId}
            id={taskId}
          />
        )}
        {showSubTaskDetail && (
          <SubTaskDetail
            onClose={() => {
              document.body.style.overflow = "auto";
              setShowSubTaskDetail(false);
            }}
            taskId={taskId}
            id={subTaskId}
          />
        )}
        
        {!isUpdateProject &&
          project._id &&
          openTaskId &&
          openSubTaskId === null &&
          isShowTaskDetail && (
            <TaskDetail
              onClose={() => dispatch(dispatchIsShowTaskDetail(false))}
              id={openTaskId}
              projectId={project._id}
            />
          )}
        {!isUpdateProject &&
          project._id &&
          openSubTaskId &&
          isShowSubTaskDetail && (
            <SubTaskDetail
              onClose={() => dispatch(dispatchIsShowSubTaskDetail(false))}
              id={openSubTaskId}
              taskid={openTaskId}
            />
          )}
      </div>
      <ConfirmModal
        isShow={isDeleteWorkspaceConfirm}
        title={"Delete Workspace"}
        message="Are you sure you want to delete this workspace?"
        onDelete={() => deleteWorkspaceData(workspaceId)}
        onClose={setIsDeleteWorkspaceConfirm}
      >
        {isItems && (
          <span>
            You cannot delete this workspace! Your workspace has projects.
          </span>
        )}
      </ConfirmModal>
      <ConfirmModal
        isShow={isDeleteProjectConfirm}
        title={"Delete Project"}
        message="Are you sure you want to delete this project?"
        onDelete={() => deleteProjectData(projectId)}
        onClose={setIsDeleteProjectConfirm}
      >
        {isItems && (
          <div>
            You cannot delete this project! Your project has tasks.
            <span
              className={styles.asLink}
              onClick={() => {
                showCompleted && showCompleted();
                setIsDeleteProjectConfirm(false);
              }}
            >
              {" "}
              Check out the completed ones
            </span>
          </div>
        )}
      </ConfirmModal>
    </>
  );
}
