import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import DatePickerCustomInput from "components/DatePickerCustomInput";
import compareAsc from "date-fns/compareAsc";
import { formatDate } from "services/helpers";
import { Form } from "react-bootstrap";
import { useWarningModal } from "hooks/useWarningModal";

const DatePickerGroup = ({ formData, setFormData, taskPermission, isDisable, projectName, projectDateFrom, projectDateTo }) => {
  registerLocale("en-US", enUS);
  const { onDisplayWarning } = useWarningModal();

  const onDateFromChanged = (value) => {
    const formattedValue = formatDate(value);
    if (projectDateFrom) {
      let compareResult = compareAsc(new Date(projectDateFrom), new Date(formattedValue));
      if (compareResult > 0) {
        const message = `Date From in ${formData.name} must match Date From in the ${projectName} ${formatDate(projectDateFrom)}`;
        onDisplayWarning(message);
        return;
      }
    }
    if (formData.date_to) {
      const compareResult = compareAsc(value, new Date(formData?.date_to));
      if (compareResult > 0) {
        const message = "DateFrom field should be before DateTo field!";
        onDisplayWarning(message);
        return;
      }
    }
    setFormData({ ...formData, date_from: formatDate(value) });
  };

  const onDateToChanged = (value) => {
    const formattedValue = formatDate(value);
    if (formData?.date_deadline) {
      let compareResult = compareAsc(new Date(formattedValue), new Date(formData?.date_deadline));
      if (compareResult > 0) {
        const message = "The DateTo value should not be after the Deadline value";
        onDisplayWarning(message);
        return;
      }
    }

    if (formData.date_from) {
      const compareResult = compareAsc(new Date(formData.date_from), value);
      if (compareResult > 0) {
        const message = "DateTo field should be after DateFrom field!";
        onDisplayWarning(message);
        return;
      }
    }
    setFormData({ ...formData, date_to: formatDate(value) });
  };

  const onDateDeadlineChanged = (value) => {
    const formattedValue = formatDate(value);
    if (projectDateTo) {
      let compareResult = compareAsc(new Date(formattedValue), new Date(projectDateTo));
      if (compareResult > 0) {
        const message = `Deadline in ${formData?.name} must match Date To in the ${projectName} ${formatDate(projectDateTo)}`;
        onDisplayWarning(message);
        return;
      }
    }
    if (formData.date_to) {
      const compareResult = compareAsc(new Date(formData.date_to), value);
      if (compareResult > 0) {
        const message = "Deadline field should be after Date To field!";
        onDisplayWarning(message);
        return;
      }
    }
    setFormData({ ...formData, date_deadline: formatDate(value) });
  };

  return (
    <div className="datesBlock mb-5 ">
      <Form.Group className="dateFrom mb-2 d-flex flex-column" style={{ border: "none" }}>
        <Form.Label>Date From</Form.Label>
        <DatePicker
          selected={formData?.date_from ? new Date(formData?.date_from) : new Date()}
          value={formData?.date_from}
          onChange={onDateFromChanged}
          disabled={isDisable}
          formatWeekDay={(day) => day.slice(0, 3)}
          locale="en-US"
          placeholderText="Date from"
          customInput={<DatePickerCustomInput isPermission={taskPermission !== null} />}
          showYearDropdown
        />
      </Form.Group>

      <Form.Group className="dateTo mb-2 d-flex flex-column">
        <Form.Label>Date to</Form.Label>
        <DatePicker
          selected={formData?.date_to ? new Date(formData?.date_to) : new Date()}
          value={formData?.date_to}
          onChange={onDateToChanged}
          disabled={isDisable}
          formatWeekDay={(day) => day.slice(0, 3)}
          locale="en-US"
          placeholderText="Date to"
          customInput={<DatePickerCustomInput isPermission={taskPermission !== null} />}
          showYearDropdown
        />
      </Form.Group>
      <Form.Group className="dateDeadline mb-2 d-flex flex-column">
        <Form.Label>Deadline</Form.Label>
        <DatePicker
          selected={formData?.date_deadline ? new Date(formData?.date_deadline) : new Date()}
          value={formData?.date_deadline}
          onChange={onDateDeadlineChanged}
          disabled={isDisable}
          formatWeekDay={(day) => day.slice(0, 3)}
          locale="en-US"
          placeholderText="Deadline"
          customInput={<DatePickerCustomInput isPermission={taskPermission !== null} />}
          showYearDropdown
        />
      </Form.Group>
    </div>
  );
};

export default DatePickerGroup;

DatePickerGroup.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  taskPermission: PropTypes.string,
  isDisable: PropTypes.bool,
  projectName: PropTypes.string,
  projectDateFrom: PropTypes.string,
  projectDateTo: PropTypes.string
};
