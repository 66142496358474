import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Flex, Spin } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { getActiveLeaveDaysRequests } from "API/calendar";
import PagePagination from "components/Pagination";
import LeavesTable from "features/calendar/LeavesTable";
import { dispatchIsAlerts } from "redux/taskSlice";
import { useWarningModal } from "hooks/useWarningModal";

const LeaveDays = ({ updateBigCalendarData, workspaceId }) => {
  const { accountISbb_accountsID } = useSelector((state) => state.appsetting.account);
  const dispatch = useDispatch();
  const { onDisplayError } = useWarningModal();

  const [loading, setLoading] = useState(false);

  const [adminsOptions, setAdminsOptions] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);

  const [leavesDays, setLeavesDays] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    getLeaveDaysData(1);
  }, []);

  const getLeaveDaysData = async (page) => {
    setLoading(true);

    await getActiveLeaveDaysRequests(null, workspaceId, page)
      .then((res) => {
        // console.log(res, "res-active-leave-requests");
        if (res.data.error) {
          onDisplayError(res.data.error);
          return;
        }
        const result = res.data.result;
        const admins = res.data.admins;
        const reasons = res.data.reasons;
        const totalPages = Math.ceil(Number(res.data?.total) / res.data?.per_page);
        setTotalPages(totalPages);
        setActivePage(page);
        if (result) {
          setLeavesDays(result);
        }
        if (reasons) {
          setLeaveTypes(reasons);
        }
        if (admins) {
          const options = admins
            .filter((item) => item.account_id !== "272") // Hanna Tsibulya - was removed for the dev server
            .map((item) => {
              return { label: item.account_name, value: item.account_id };
            });
          setAdminsOptions(options);
          const ids = admins.map((item) => {
            return item.account_id;
          });
          const is_admin = ids.some((id) => id === accountISbb_accountsID);
          setIsAdmin(is_admin);
        }
        dispatch(dispatchIsAlerts(Date.now()))
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.location.href = "https://account2.task925.com/?killsession";
        } else onDisplayError(err?.message || err?.data?.error);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" className="tw-w-full tw-h-20">
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <Flex vertical className="tw-w-full" gap={32}>
      <Flex vertical className="tw-w-full">
        {leavesDays?.length > 0 && (
          <LeavesTable
            leavesDays={leavesDays}
            isAdmin={isAdmin}
            adminsOptions={adminsOptions}
            leaveTypes={leaveTypes}
            updateCalendarData={getLeaveDaysData}
            updateBigCalendarData={updateBigCalendarData}
            workspaceId={workspaceId}
          />
        )}
      </Flex>
      {totalPages > 1 && (
        <Flex justify="center" className="tw-w-full">
          <PagePagination totalPages={totalPages} onPaginationClick={getLeaveDaysData} activePage={activePage} />
        </Flex>
      )}
    </Flex>
  );
};

export default LeaveDays;

LeaveDays.propTypes = {
  workspaceId: PropTypes.string,
  leavesDays: PropTypes.array,
  adminsOptions: PropTypes.array,
  updateCalendarData: PropTypes.func,
  updateBigCalendarData: PropTypes.func,
  adminsIds: PropTypes.array
};
