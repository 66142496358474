import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "features/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { Flex, Button } from "antd";
import ButtonSpinner from "components/ButtonSpinner";
import DocumentsLinks from "components/DocumentsLinks";
import Timeline from "components/ChronoTimeline";
import FieldsListView from "components/FieldListView";
import TagMultiSelect from "components/TagMultiSelect";
import LoadingTimeline from "components/LoadingTimeline";
import Message from "./Message";
import Status from "./Status";
import { getParticipants } from "API/participants";
import { getTask, addSubTask, editTask } from "API/tasks";
import { setIsUpdateProject } from "redux/projectSlice";
import { formatDate } from "services/helpers";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import DraftEditor from "components/DraftEditor";
import { isJSONRegExp } from "services/helpers";
import useTabletWindow from "hooks/useTabletWindow";
import { dispatcOpenTaskLoading, dispatchIsAlerts } from "redux/taskSlice";
import useMobileWindow from "hooks/useMobileWindow";
import { parseEmailData, loadContent, saveContent } from "./helpers";
import MultiSelect from "components/MultiSelect";
import SwitchGroup from "features/task/forms/SwitchGroup";
import { useModalActions } from "features/task/hooks/useModalActions";
import DatePickerGroup from "features/task/forms/DatePickerGroup";
import DescLoadingShimmer from "features/task/forms/DescLoadingShimmer";
import { useWarningModal } from "hooks/useWarningModal";

const SubTaskDetail = ({ onClose, id, taskId }) => {
  const { onDisplayError } = useWarningModal();

  const isTablet = useTabletWindow();
  const isMobile = useMobileWindow();

  const project = useSelector((state) => state.project.project);
  const { permission, _id: projectId } = project;
  const dispatch = useDispatch();
  const today = formatDate(new Date());
  const initialFormData = {
    name: "",
    detailsISsmallplaintextbox: EditorState.createEmpty(),
    internal_detailsISsmallplaintextbox: EditorState.createEmpty(),
    task_owner_id: "0",
    task_actionedYN: false,
    task_closedYN: false,
    date_from: today,
    date_to: today,
    date_deadline: today
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isSubTask, setIsSubTask] = useState(false);
  const [taskFields, setTaskFields] = useState([]);
  const [taskTags, setTaskTags] = useState([]);
  const [taskTagsList, setTaskTagsList] = useState([]);

  const [loggerName, setLoggerName] = useState("");
  const [addedDate, setAddedDate] = useState("");
  const [closedDate, setClosedDate] = useState("");
  const [observers, setObservers] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [actionerIds, setActionersIds] = useState([]);
  const [fullParticipantsData, setFullParticipantsData] = useState([]);
  const [selecetedActioners, setSelecetedActioners] = useState([]);

  const [timelines, setTimelines] = useState([]);
  const [currentSubTaskId, setCurrentSubTaskId] = useState(null);
  const [errorName, setErrorName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDocumentsLinks, setIsDocumentsLinks] = useState(true);
  const [isSpinnerLoad, setIsSpinnerLoad] = useState(false);
  const [taskProjectId, setTaskProjectId] = useState("");
  const [taskPermission, setTaskPermission] = useState(null);

  useEffect(() => {
    if (id) {
      getInitSubTaskData(id);
      setCurrentSubTaskId(id);
    }
  }, [id]);

  const getInitSubTaskData = async (id) => {
    // formData.append("api_method", "get_task");
    // formData.append("task_id", id);
    try {
      setLoading(true);
      const response = await getTask(id);
      if (response.data.success === 1) {
        const task = response.data.task;
        // console.log(task, "subtask");
        dispatch(dispatchIsAlerts(task?._id));

        setTaskProjectId(task.projectISbb_t925_projectsID);
        setTaskPermission(task.permission);
        setIsSubTask(Boolean(task.parent_task_id));
        setTimelines(task.timeline);

        const parsedDescriptionData =
          task.detailsISsmallplaintextbox !== null && isJSONRegExp(task.detailsISsmallplaintextbox)
            ? loadContent(task.detailsISsmallplaintextbox)
            : task.detailsISsmallplaintextbox !== null
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(parseEmailData(task.detailsISsmallplaintextbox)))
              )
            : EditorState.createEmpty();

        const parsedInternalDescription =
          task.internal_detailsISsmallplaintextbox !== null && isJSONRegExp(task.internal_detailsISsmallplaintextbox)
            ? loadContent(task.internal_detailsISsmallplaintextbox)
            : task.internal_detailsISsmallplaintextbox !== null
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(parseEmailData(task.internal_detailsISsmallplaintextbox)))
              )
            : EditorState.createEmpty();

        setFormData({
          name: task?.name,
          detailsISsmallplaintextbox: parsedDescriptionData,
          internal_detailsISsmallplaintextbox: parsedInternalDescription,
          task_owner_id: task.task_owner_id === null ? "0" : task.task_owner_id,
          task_actionedYN: !!parseFloat(task.task_actionedYN),
          task_closedYN: !!parseFloat(task.task_closedYN),
          date_from: task.date_from === null ? today : formatDate(task.date_from),
          date_to: task.date_to === null ? today : formatDate(task.date_to),
          date_deadline: task.date_deadline === null ? "" : formatDate(task.date_deadline)
        });

        if (task.actioners.length > 0) {
          const actionerNames = task.actioners.map((item) => item.name);
          setSelecetedActioners(actionerNames);
          setActionersIds(task.actioners.map((item) => item._id));
        }

        const taskFieldsList = task.task_fields_list;
        setTaskFields(taskFieldsList === null ? [] : taskFieldsList.reverse());
        const taskTagsList = task.task_tags_list;
        setTaskTagsList(taskTagsList === null ? [] : taskTagsList);
        setTaskTags(task.task_tags === null ? [] : task.task_tags);

        setLoggerName(task.logger_name === null ? "" : task.logger_name);
        setAddedDate(task._dateadded === null ? "" : task._dateadded.split(" ")[0]);
        setClosedDate(task.datetime_closed === null ? "" : task.datetime_closed.split(" ")[0]);

        await setParticipantsAsync(task.projectISbb_t925_projectsID);
      } else if (response?.data?.error) {
        const message = response.data.message || response?.data?.error;
        onDisplayError(message);
      }
      setLoading(false);
      dispatch(dispatcOpenTaskLoading(false));
    } catch (err) {
      onDisplayError(err.toString());
    }
  };

  const setParticipantsAsync = async (taskProjectId) => {
    await getParticipants(null, projectId !== "0" ? projectId : taskProjectId)
      .then((res) => {
        if (res?.data?.error) {
          onDisplayError(res.data.error);
        }
        const participantsData = res?.data?.list;
        setObservers(participantsData.filter((item) => item.type === "Observer" && item.account_id !== null));
        const fullArrParticipants = participantsData?.filter((item) => item.type !== "Observer" && item.account_id !== null);
        setParticipants(fullArrParticipants.map((el) => el.account_name));
        setFullParticipantsData(fullArrParticipants);
      })
      .catch((err) => {
        onDisplayError(err.response.data.error);
      });
  };

  const onDoneSubTask = async () => {
    if (loading) {
      onClose();
      document.body.classList.add("overflow-auto");
      document.body.classList.remove("overflow-hidden");
    }
    if (currentSubTaskId && formData?.name === "") {
      setErrorName("The Title field is required");
      return;
    }
    const fData = {
      ...formData,
      task_id: currentSubTaskId,
      detailsISsmallplaintextbox: saveContent(formData?.detailsISsmallplaintextbox),
      internal_detailsISsmallplaintextbox: saveContent(formData?.internal_detailsISsmallplaintextbox),
      actioner_id: actionerIds
    };

    if (currentSubTaskId) {
      setIsSpinnerLoad(true);
      await editTask(fData)
        .then((res) => {
          if (res?.data?.error) {
            onDisplayError(res.data.error);
            return;
          }
          dispatch(setIsUpdateProject(true));
        })
        .catch((err) => {
          if (err?.response?.status) {
            onDisplayError(err.response.data.error);
          }
        });
    }
    onClose();
    document.body.classList.add("overflow-auto");
    document.body.classList.remove("overflow-hidden");
  };

  const addNewSubTask = async () => {
    if (formData?.name === "") {
      setErrorName("The Title field is required");
      return;
    }
    if (currentSubTaskId === null) {
      await addSubTask({ ...formData, task_id: taskId })
        .then((res) => {
          // console.log(res, res.data, "add-subtask");
          if (res?.data?.error) {
            onDisplayError(res?.data?.error);
          }
          const newSubTaskId = res.data.new_task_id.toString();
          setCurrentSubTaskId(newSubTaskId);
          getInitSubTaskData(newSubTaskId);
          dispatch(setIsUpdateProject(true));
        })
        .catch((err) => {
          if (err?.response?.status) {
            onDisplayError(err.response.data.error);
          }
        });
    }
  };

  const onActionerToChanged = (data) => {
    setSelecetedActioners(data.map((el) => el.value));
    const accountIds = fullParticipantsData
      .filter((item) => data.some((option) => option.value === item.account_name))
      .map((item) => item.account_id);
    // console.log(accountIds, "accountIds");
    setActionersIds(accountIds);
  };

  const { onCloseWithoutSaving, onDeleteTask } = useModalActions(onClose);

  const isPermission = permission === "Owner" || permission === "Admin" || permission === "Project Manager";

  const isDisable = currentSubTaskId === null || !isPermission;

  const title = id === null ? "New Sub-task" : isPermission ? `Edit Sub-task TK(${id})` : `View Sub-task TK(${id})`;

  return (
    <>
      <div className="modal-container" onClick={onCloseWithoutSaving}>
        <div
          className="modal-content"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Form>
            <div className="formWrapper" style={{ minHeight: "100%" }}>
              <div className="px-4 bg-white mainBlock">
                <div className="bg-white py-4 d-flex justify-content-between align-items-center">
                  <span className="fs-5 fw-bold">{title}</span>
                  <Flex align="center">
                    {id && (
                      <Button size="sm" onClick={onCloseWithoutSaving} className="me-2 btn-warning">
                        Close
                      </Button>
                    )}
                    <ButtonSpinner onClick={onDoneSubTask} loading={isSpinnerLoad}>
                      Done
                    </ButtonSpinner>
                    {(isMobile || isTablet) && isPermission && (
                      <Button
                        className="ms-2 btn-sm btn-outline-danger"
                        onClick={() => onDeleteTask(currentSubTaskId)}
                        style={{ float: "right" }}
                        disabled={isDisable}
                      >
                        Delete
                      </Button>
                    )}
                  </Flex>
                </div>
                <div className="nameBlock mb-5">
                  <Form.Group className="nameInput">
                    <Form.Label>Title</Form.Label>
                    {loading ? (
                      <div className="animated-background mx-2"></div>
                    ) : (
                      <Form.Control
                        type="text"
                        placeholder="Task title"
                        value={formData?.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        onBlur={addNewSubTask}
                        className={errorName ? "border-danger" : ""}
                        disabled={currentSubTaskId && !isPermission}
                        style={{
                          backgroundColor: !isPermission ? "transparent" : ""
                        }}
                      />
                    )}
                    {errorName && (
                      <div className="text-danger" style={{ fontSize: "12px" }}>
                        {errorName}
                      </div>
                    )}
                  </Form.Group>
                </div>
                <Form.Group className="mb-5" controlId="taskDescription">
                  <Form.Label>Description</Form.Label>
                  {loading ? (
                    <DescLoadingShimmer />
                  ) : (
                    <DraftEditor
                      value={formData?.detailsISsmallplaintextbox}
                      onDescriptionChange={(data) => setFormData({ ...formData, detailsISsmallplaintextbox: data })}
                      isDisable={currentSubTaskId === null}
                      isReadOnly={!isPermission}
                    />
                  )}
                </Form.Group>

                {(permission !== "Observer" ||
                  (currentSubTaskId === null && permission === "Observer") ||
                  (taskPermission === "Owner" && permission === "Observer")) && (
                  <Form.Group className="mb-5" controlId="taskInternalDescription">
                    <>
                      <Form.Label>Internal Description</Form.Label>
                      {loading ? (
                        <DescLoadingShimmer />
                      ) : (
                        <DraftEditor
                          value={formData?.internal_detailsISsmallplaintextbox}
                          onDescriptionChange={(data) => setFormData({ ...formData, internal_detailsISsmallplaintextbox: data })}
                          isDisable={currentSubTaskId === null}
                          isReadOnly={!isPermission}
                        />
                      )}
                    </>
                  </Form.Group>
                )}

                <div className="mb-5">
                  {loading ? (
                    <>
                      <div className="d-flex flex-column my-2 w-50">
                        <Form.Label>Owner/Customer</Form.Label>
                        <div className="animated-background mx-2"></div>
                      </div>
                      <div className="d-flex flex-column my-2 w-50">
                        <Form.Label>Actioners</Form.Label>
                        <div className="animated-background mx-2"></div>
                      </div>
                    </>
                  ) : (
                    <div className="selectsBlock">
                      <Form.Group className="selectItem me-3">
                        <Form.Label>Owner/Customer</Form.Label>
                        <Form.Select
                          value={formData.task_owner_id}
                          onChange={(e) => setFormData({ ...formData, task_owner_id: e.target.value })}
                          id={formData.task_owner_id === "0" || formData.task_owner_id === "" ? "readOnlyPlaceholderId" : "selectId"}
                          style={!isPermission && currentSubTaskId !== null ? { backgroundColor: "transparent" } : {}}
                          disabled={isDisable}
                          required
                        >
                          <option value="" hidden>
                            Owner/Customer
                          </option>
                          <option value="">None</option>
                          {observers.map((participant) => (
                            <option value={participant._id} key={participant._id}>
                              {participant.account_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="selectItem">
                        <Form.Label>Actioners</Form.Label>
                        <MultiSelect
                          onChange={(data) => onActionerToChanged(data)}
                          options={participants}
                          selected={selecetedActioners}
                          placeholder="Actioner"
                          disabled={isDisable}
                          required
                          style={{ height: "36px" }}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>
                <DatePickerGroup formData={formData} setFormData={setFormData} taskPermission={taskPermission} isDisable={isDisable} />

                {loading ? (
                  <div className="animated-background mx-2"></div>
                ) : taskTags.length > 0 || isPermission ? (
                  <Form.Group className={taskFields.length !== 0 ? "" : "mb-5"}>
                    <Form.Label>Tags</Form.Label>
                    <TagMultiSelect
                      taskId={currentSubTaskId}
                      projectId={projectId !== "0" ? projectId : taskProjectId}
                      taskTagsList={taskTagsList}
                      savedTags={taskTags}
                      disabled={currentSubTaskId === null || taskPermission === "Actioner" || taskPermission === "Observer"}
                      readOnly={taskPermission === "Actioner" || taskPermission === "Observer"}
                    />
                  </Form.Group>
                ) : null}

                {taskFields.length > 0 && <div className="task-hr my-4"></div>}
                <Form.Group>
                  {taskFields.length > 0 && (
                    <>
                      <Form.Label>Fields</Form.Label>
                      <FieldsListView
                        taskId={currentSubTaskId}
                        projectId={projectId !== "0" ? projectId : taskProjectId}
                        savedFields={taskFields}
                        disabled={currentSubTaskId === null}
                        isReadOnly={taskPermission === "Observer"}
                        taskPermission={taskPermission}
                      />
                    </>
                  )}
                </Form.Group>
                {taskFields.length > 0 && <div className="task-hr mt-2 mb-4"></div>}

                <SwitchGroup
                  isSubTask={true}
                  formData={formData}
                  setFormData={setFormData}
                  permission={permission}
                  taskId={currentSubTaskId}
                  isDisable={isDisable}
                />

                <Status
                  taskId={currentSubTaskId}
                  loggerName={loggerName}
                  addedDate={addedDate}
                  closedDate={closedDate}
                  permission={permission}
                  isSubTask={isSubTask}
                />

                <Form.Group className="w-100 my-5">
                  <Form.Label>Notes & Messages</Form.Label>
                  <Message taskId={currentSubTaskId} />
                </Form.Group>

                {(isMobile || isTablet) && (isPermission || isDocumentsLinks) && (
                  <Form.Group className="mb-5">
                    <Form.Label>Documents & Links</Form.Label>
                    <DocumentsLinks
                      isDisabled={currentSubTaskId === null}
                      taskId={currentSubTaskId}
                      setIsDocumentsLinks={setIsDocumentsLinks}
                      isPermission={isPermission}
                    />
                  </Form.Group>
                )}

                {isTablet && !!timelines.length && (
                  <Form.Group className="mb-5 w-100 timelineHorizontal">
                    <Form.Label className="fs-16 mb-3">Timeline</Form.Label>
                    {loading ? <LoadingTimeline /> : <Timeline values={timelines} mode="HORIZONTAL" />}
                  </Form.Group>
                )}
              </div>
              <div className="participants" style={{ width: "10px", backgroundColor: "gray", opacity: 0.7 }}></div>
              <div className="px-4 pt-4 bg-white rounded participants" style={{ width: "31%" }}>
                {isPermission && (
                  <Button
                    className="mb-5 btn-sm btn-outline-danger"
                    onClick={() => onDeleteTask(currentSubTaskId)}
                    style={{ float: "right" }}
                    disabled={isDisable}
                  >
                    Delete
                  </Button>
                )}
                {(isPermission || isDocumentsLinks) && (
                  <Form.Group className="my-5">
                    <Form.Label>Documents & Links</Form.Label>
                    <DocumentsLinks
                      isDisabled={currentSubTaskId === null}
                      taskId={currentSubTaskId}
                      setIsDocumentsLinks={setIsDocumentsLinks}
                      isPermission={isPermission}
                    />
                  </Form.Group>
                )}

                {!!timelines.length && (
                  <Form.Group className="mb-3 w-100 timelineHorizontal">
                    <Form.Label className="mb-3">Timeline</Form.Label>
                    {loading ? <LoadingTimeline /> : <Timeline values={timelines} mode="VERTICAL" />}
                  </Form.Group>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
SubTaskDetail.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  taskId: PropTypes.string
};
export default SubTaskDetail;
