import React, { useState } from "react";
import "./navbar.css";
import "./../../App.css";
import styles from "./styles.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, Image, Button } from "react-bootstrap";
// import { Dropdown } from "antd";

import { useNavigate/*, useLocation*/ } from "react-router-dom";
import { GearFill } from "react-bootstrap-icons";
import AlertModal from "components/AlertModal";
import SettingsModal from "../SettingsModal";
import { setError/*, setIsProjectChosen */} from "redux/workspaceSlice";
import WorkspaceDropdown from "layouts/Navbar/WorkspaceDropdown";
import ProjectsDropdown from "layouts/Navbar/ProjectsDropdown";
import ReportsDropdown from "layouts/Navbar/ReportsDropdown";
import WorkspaceDetail from "features/workspace/WorkspaceDetail";
import SearchInput from "components/SearchInput";
import MobileNavbar from "./MobileNavbar";
import useMobileWindow from "hooks/useMobileWindow";
import LogoutDropdown from "layouts/Navbar//LogoutDropdown";
import Notifications from "layouts/Navbar/Notifications/Notifications";

const NavbarPanel = () => {
  // let location = useLocation();
  const isMobile = useMobileWindow();

  const error = useSelector((state) => state.workspaces.error);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSetting, setShowSetting] = useState(false);
  const [show, setShow] = useState(false);

  const onModalClosed = () => {
    dispatch(
      setError({
        isShow: false,
        title: "",
        message: ""
      })
    );
  };

  // const myTeamMenu = [
  //   {
  //     key: "1",
  //     label: "My Team",
  //     onClick: () => {
  //       navigate("/team");
  //       dispatch(setIsProjectChosen(false));
  //     },
  //   },
  //   {
  //     key: "2",
  //     label: "My Calendar",
  //     onClick: () => {
  //       navigate("/calendar");
  //       dispatch(setIsProjectChosen(false));
  //     },
  //   },
  // ];
  return (
    <>
      {isMobile ? (
        <MobileNavbar setShow={setShow} />
      ) : (
        <Navbar expand="lg" className="d-flex justify-content-between align-items-center flex-wrap px-5">
          <Navbar.Brand style={{ cursor: "pointer" }} className="navItem" onClick={() => navigate("/dashboard")}>
            <Image src="/imgs/logo.png" style={{ maxHeight: 50 }}></Image>
          </Navbar.Brand>

          <div className="navItem">
            <WorkspaceDropdown />
            <ProjectsDropdown />
            {/* <Dropdown menu={{items:myTeamMenu}} trigger={["click"]}>
              <span className={`${(location.pathname === "/team" || location.pathname === "/calendar") ? styles.activeDropdown : styles.selectWorkspace}`}>
                {location.pathname === "/calendar" ? "My Calendar" : "My Team"}
              </span>
            </Dropdown> */}
            <ReportsDropdown />
          </div>

          <div className="navItem">
            <Button
              style={{ minWidth: "160px", height: "37.6px" }}
              className={`me-2 ${styles.activeButton}`}
              onClick={() => {
                setShow(true);
                document.body.classList.remove("overflow-auto");
                document.body.classList.add("overflow-hidden");
              }}
            >
              New Workspace
            </Button>
            <SearchInput />
            <Notifications/>
            <div className="top-nav-icon px-2" role="button">
              <GearFill
                style={{ fontSize: "1.25rem" }}
                className="text-primary"
                onClick={() => {
                  setShowSetting(true);
                }}
              />
            </div>
            <LogoutDropdown />
          </div>
        </Navbar>
      )}
      {show && <WorkspaceDetail onClose={() => setShow(false)} id={null} />}

      <AlertModal isShow={error.isShow} title={error.title} message={error.message} onClose={onModalClosed} />
      <SettingsModal showSetting={showSetting} onHide={() => setShowSetting(false)} />
    </>
  );
};

export default NavbarPanel;
