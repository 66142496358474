import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import "./mobile.css";
import { Flex } from "antd";
import { useDispatch } from "react-redux";
import { FaBars, FaTimes } from "react-icons/fa";
import { Navbar, Image } from "react-bootstrap";
// import ReportsDropdown from "layouts/Navbar/ReportsDropdown";
import WorkspaceDropdown from "layouts/Navbar/WorkspaceDropdown";
import ProjectsDropdown from "layouts/Navbar/ProjectsDropdown";
import LogoutDropdown from "layouts/Navbar//LogoutDropdown";
import { useNavigate } from "react-router-dom";
import { setIsProjectChosen } from "redux/workspaceSlice";
import SearchInput from "components/SearchInput";
import Notifications from "layouts/Navbar/Notifications/Notifications";

const MobileNavbar = ({ setShow }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isNav, setIsNav] = useState(false);
  const navRef = useRef();

  const handleClick = (e) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setIsNav(false);
    }
  };

  const handleToggle = () => {
    setIsNav(!isNav);
  };
  const goToPage = (path) => {
    navigate(path);
    dispatch(setIsProjectChosen(false));
    handleToggle();
  };
  return (
    <Navbar expand="lg" className="d-flex justify-content-between align-items-center">
      <div className="nav-container" ref={navRef}>
        {isNav ? null : (
          <Flex vertical className="tw-w-full">
            <Flex justify="space-between" align="center" className="tw-p-0">
              <Flex justify="space-between" align="center">
                <div onClick={handleToggle}>
                  <FaBars style={{ width: "40px", height: "30px" }} />
                </div>
                <Navbar.Brand style={{ cursor: "pointer" }} className="p-0">
                  <Image src="/imgs/logo.png" style={{ maxHeight: 40 }}></Image>
                </Navbar.Brand>
              </Flex>
              <Flex align="center" gap={8}>
                <Notifications />
                <LogoutDropdown />
              </Flex>
            </Flex>
            <SearchInput />
          </Flex>
        )}
        <div className={isNav ? "nav-links active" : "nav-links"}>
          <Flex justify="space-between" align="center" className="tw-w-full tw-mt-3 tw-px-3">
            <Navbar.Brand className="tw-cursor-pointer" onClick={() => goToPage("/dashboard")}>
              <Image src="/imgs/logo.png" style={{ maxHeight: 40 }}></Image>
            </Navbar.Brand>
            <span className="nav-close" onClick={handleToggle}>
              <FaTimes />
            </span>
          </Flex>
          <ul className="tw-flex tw-w-full tw-px-3 tw-gap-3">
            {/* <li onClick={handleToggle}>
              <Link to="/dashboard">Dashboard</Link>
            </li> */}
            {/* <li>
              <ReportsDropdown toggleMobileNav={handleToggle}/>
            </li> */}
            <li>
              <WorkspaceDropdown toggleMobileNav={handleToggle} isMobileNav={isNav} />
            </li>
            <li>
              <ProjectsDropdown toggleMobileNav={handleToggle} isMobileNav={isNav} />
            </li>
            <li
              onClick={() => {
                handleToggle();
                setShow(true);
                document.body.classList.remove("overflow-auto");
                document.body.classList.add("overflow-hidden");
              }}
              className="tw-px-4 tw-cursor-pointer"
            >
              New Workspace
            </li>
            <li onClick={() => goToPage("/team")} className="tw-px-4 tw-cursor-pointer">
              My Team
            </li>
            <li onClick={() => goToPage("/calendar")} className="tw-px-4 tw-cursor-pointer">
              Calendar
            </li>
          </ul>
        </div>
      </div>
      {isNav && <div className="nav-overlay" onClick={handleClick}></div>}
    </Navbar>
  );
};

MobileNavbar.propTypes = {
  setShow: PropTypes.func
};
export default MobileNavbar;
