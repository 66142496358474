import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Participants.module.scss";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PlusIconButton, XIconButton } from "./IconButton";
import { getParticipants, addParticipant, editParticipant, deleteParticipant } from "API/participants";
import LoadingParticipants from "components/LoadingParticipants";
// import { deepEqualObj } from "services/helpers";
import useMobileWindow from "hooks/useMobileWindow";
import { useWarningModal } from "hooks/useWarningModal";

const Participants = React.memo(({ disabled, workspaceId = null, projectId = null, isProject = false }) => {
  console.log(workspaceId, projectId, "workspaceId = null, projectId = null");
  const isMobile = useMobileWindow();
  const { onDisplayError } = useWarningModal();

  const allParticipantTypes = useSelector((state) => state.appsetting.participant_types);
  const status = useSelector((state) => state.appsetting.participant_status);
  const [types, setTypes] = useState([]);
  const [afterAddParticipants, setAfterAddParticipants] = useState([]);
  // const [initParticipantsData, setInitParticipantsData] = useState([]);

  const initParticipants = {
    _id: null,
    email: "",
    type: projectId ? "" : "Admin",
    status: ""
  };
  const [participants, setParticipants] = useState([initParticipants]);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    if (workspaceId || projectId) {
      setInitData();
      // console.log(participantTypes, 'participantTypes', projectPerticipantTypes)
      const participant_types = workspaceId === null ? allParticipantTypes.filter((item) => item !== "Admin") : ["Admin"];
      setTypes(participant_types);
    }
  }, [workspaceId, projectId]);

  // Update data if the save was performed by clicking on the PlusIcon - OnNewClicked().
  // In this case, we cannot update the data correctly only with onBlur()
  useEffect(() => {
    if (afterAddParticipants.length !== 0) {
      const updatedParticipants = participants.map((participant, index) => {
        if (participant._id === null) {
          if (index < afterAddParticipants.length) {
            return {
              ...participant,
              _id: afterAddParticipants[index]._id
            };
          } else {
            return participant;
          }
        } else {
          return participant;
        }
      });
      setParticipants(updatedParticipants);
      setAfterAddParticipants([]);
    }
  }, [afterAddParticipants]);

  const setInitData = async () => {
    try {
      setLoading(true);
      const res = await getParticipants(workspaceId, projectId);
      if (res?.data?.error) {
        onDisplayError(res.data.error);
        return;
      }
      if (res?.data?.success === 1) {
        const participantList = res.data.list;
        // console.log(participantList, "participantList");
        if (participantList?.length) {
          setParticipants([...participantList.reverse()]);
          // const initEditeData = participantList.map((item) => {
          //   return {
          //     email: item.email,
          //     type: item.type,
          //     status: item.status
          //   };
          // });
          // setInitParticipantsData(initEditeData);
        } else {
          setParticipants([initParticipants]);
        }
      }
      setLoading(false);
    } catch (err) {
      onDisplayError(err.toString());
      setLoading(false);
    }
  };

  const onEmailChanged = (e, index) => {
    const newParticipants = participants.map((participant, i) => {
      if (i === index) return Object.assign({}, participant, { email: e.target.value });
      else return participant;
    });
    setParticipants(newParticipants);
  };

  const onTypeChanged = (e, index) => {
    const newParticipants = participants.map((participant, i) => {
      if (i === index) return Object.assign({}, participant, { type: e.target.value });
      else return participant;
    });
    setParticipants(newParticipants);
  };

  const onStatusChanged = (e, index) => {
    const newParticipants = participants.map((participant, i) => {
      if (i === index) return Object.assign({}, participant, { status: e.target.value });
      else return participant;
    });
    setParticipants(newParticipants);
  };

  const onNewClicked = () => {
    setParticipants([...participants, initParticipants]);
  };

  const onBlur = async (index) => {
    const participant = participants.find((p, i) => i === index);
    // const initParticipantObj = initParticipantsData.find((p, i) => i === index);
    validate();
    const hasError = validate()
      .filter((el, i) => i === index)
      .some((item) => Object.values(item).some((value) => value !== ""));
    if (hasError) return;

    const formData = {
      email: participant.email,
      type: participant.type,
      status: participant.status,
      workspace_id: workspaceId === undefined ? "" : workspaceId,
      project_id: projectId === undefined ? "" : projectId
    };
    // const editedFormObj = {
    //   email: participant.email,
    //   type: participant.type,
    //   status: participant.status
    // };

    // const isFormDataChanged = participant._id ? !deepEqualObj(initParticipantObj, editedFormObj) : null;
    try {
      const res =
        participant._id === null
          ? await addParticipant(formData)
          : // : isFormDataChanged
            // ?
            await editParticipant({
              ...formData,
              participant_id: participant._id
            });
      // : null;
      if (res?.data?.error) {
        onDisplayError(res?.data?.error);
        return;
      }
      if (res?.data?.success === 1) {
        if (participant._id === null) {
          const newParticipants = participants.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                _id: res?.data?.new_participant_id.toString()
              };
            }
            return item;
          });
          setAfterAddParticipants(newParticipants);
        }
        //  else {
        //   const initEditeData = participants.map((item) => {
        //     return {
        //       email: item.email,
        //       type: item.type,
        //       status: item.status
        //     };
        //   });
        //   setInitParticipantsData(initEditeData);
        // }
      }
    } catch (err) {
      onDisplayError(err.toString());
    }
  };

  const validate = () => {
    let errors = [];
    participants.forEach((item) => {
      let error = {
        email: "",
        type: "",
        status: ""
      };
      const regexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!regexp.test(item?.email) || !item.email) {
        error.email = "This field is required";
      }

      if (!item.type) {
        error.type = "This field is required";
      }

      if (!item.status) {
        error.status = "This field is required";
      }
      errors.push(error);
    });
    setFormErrors(errors);
    return errors;
  };

  const onDeleteClicked = async (index) => {
    const targetParticipant = participants.find((participant, i) => i === index);
    console.log(targetParticipant, "deleteParticipant");
    const targetId = targetParticipant._id;
    if (targetId !== null) {
      // formData.append("api_method", "delete_participant");
      // formData.append("participant_id", targetId);
      const userId =
        targetParticipant.project_id === "0"
          ? { workspace_id: targetParticipant?.workspace_id }
          : { project_id: targetParticipant?.project_id };
      try {
        const res = await deleteParticipant({
          participant_id: targetId,
          ...userId
        });
        if (res?.data?.error) {
          onDisplayError(res?.data?.error);
          return;
        }
        if (res?.data?.success === 1) {
          const newParticipants = participants.filter((particiapnt, i) => i !== index);
          newParticipants.length !== 0
            ? setParticipants(newParticipants)
            : setParticipants([{ _id: null, email: "", type: "", status: "" }]);
        }
      } catch (err) {
        onDisplayError(err?.toString());
      }
    } else if (participants.length > 1) {
      const newParticipants = participants.filter((participant, i) => i !== index);
      setParticipants(newParticipants);
    }
    const newFormErrors = formErrors.filter((participant, i) => i !== index);
    setFormErrors(newFormErrors);
  };
  // console.log(participants, "participants before render");
  return (
    <>
      {loading ? (
        <div className={styles.participantsWrapper}>
          <LoadingParticipants />
        </div>
      ) : (
        <div className="d-flex flex-column w-100">
          <div className="d-flex align-items-center justify-content-between">
            <Form.Label className="">Participants</Form.Label>
            {isMobile && (
              <div className={styles.btnPlus}>
                <PlusIconButton onClick={onNewClicked} disabled={disabled} />
              </div>
            )}
          </div>
          <div className={styles.participantsWrapper}>
            {!isMobile && (
              <div className={styles.btnPlus}>
                <PlusIconButton onClick={onNewClicked} disabled={disabled} />
              </div>
            )}
            <div className="d-flex flex-column w-100">
              {participants.map((participant, i) => (
                <div key={i} className={i === participants.length - 1 ? styles.lastItem : styles.participantForm}>
                  <div
                    className={styles.email}
                    // className={`d-flex align-items-end w-100 ${styles.email}`}
                    style={{ flexGrow: 1 }}
                  >
                    <Form.Group
                      className="w-100"
                      // clasName={styles.email}
                    >
                      {i === 0 && <Form.Label>Email</Form.Label>}
                      <Form.Control
                        type="text"
                        placeholder="Participant email"
                        disabled={disabled || (isProject && participant.project_id === "0")}
                        value={participant.email}
                        onChange={(e) => onEmailChanged(e, i)}
                        onBlur={() => onBlur(i)}
                        style={{
                          height: "36px",
                          opacity: participant.account_id === null ? "0.5" : null
                        }}
                        className={formErrors[i] && formErrors[i].email ? "border-danger" : ""}
                      />
                    </Form.Group>
                    {(participant.project_id !== "0" || isProject === false) && (
                      <div className={`ms-2 ${styles.fullScreenDeleteBtn}`}>
                        <XIconButton
                          onClick={() => onDeleteClicked(i)}
                          disabled={disabled || (isProject && participant.project_id === "0")}
                        />
                      </div>
                    )}
                  </div>
                  <div className={`mt-2 ${styles.typeStatusBlock}`} style={{ flexGrow: 1 }}>
                    <Form.Group className={styles.type}>
                      {i === 0 && <Form.Label>Type</Form.Label>}
                      <Form.Select
                        disabled={disabled || (isProject && participant.project_id === "0")}
                        value={participant.type}
                        onChange={(e) => onTypeChanged(e, i)}
                        onBlur={() => onBlur(i)}
                        required
                        style={{
                          height: "36px",
                          opacity: participant.account_id === null ? "0.5" : null
                        }}
                        className={formErrors[i] && formErrors[i].type ? "border-danger" : ""}
                      >
                        <option value="" disabled hidden>
                          Type...
                        </option>
                        {(isProject && participant.project_id === "0" ? allParticipantTypes : types).map((type, index) => (
                          <option key={index} value={type}>
                            {type}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <div className={`${styles.status}`} style={{ flexGrow: 1 }}>
                      <Form.Group className={`w-100`} style={{ flexGrow: 1 }}>
                        {i === 0 && <Form.Label>Status</Form.Label>}
                        <Form.Select
                          disabled={disabled || (isProject && participant.project_id === "0")}
                          value={participant.status}
                          onChange={(e) => onStatusChanged(e, i)}
                          onBlur={() => onBlur(i)}
                          required
                          style={{
                            height: "36px",
                            opacity: participant.account_id === null ? "0.5" : null
                          }}
                          className={formErrors[i] && formErrors[i].status ? "border-danger" : ""}
                        >
                          <option value="" disabled hidden>
                            Status...
                          </option>
                          {status.map((stat, i) => (
                            <option key={i} value={stat}>
                              {stat}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      {isMobile && (participant.project_id !== "0" || isProject === false) && (
                        // ${i === 0 && }
                        <div className={`ms-2 ${styles.deleteButton}`} style={{ marginTop: i === 0 && "24px" }}>
                          <XIconButton
                            onClick={() => onDeleteClicked(i)}
                            disabled={disabled || (isProject && participant.project_id === "0")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {!isMobile && (participant.project_id !== "0" || isProject === false) && (
                    <div className={`ms-2 ${styles.deleteButton}`}>
                      <XIconButton
                        onClick={() => onDeleteClicked(i)}
                        disabled={disabled || (isProject && participant.project_id === "0")}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
});

Participants.propTypes = {
  workspaceId: PropTypes.string,
  projectId: PropTypes.string,
  isProject: PropTypes.bool,
  disabled: PropTypes.bool
};
export default Participants;
