import { App as AntDesignApp, Flex, Button } from "antd";

export const useConfirmModal = () => {
  const { modal } = AntDesignApp.useApp();

  const onConfirmDelete = (itemName, onDelete) => {
    console.log(itemName, "itemname-onConfirmDelete");
    const modalInstance = modal.confirm({
      title: <Flex className="tw-text-xl">Confirm delete</Flex>,
      content: (
        <Flex justify="center" className="tw-w-full tw-text-lg">
          <span>Are you sure you want to delete {itemName}?</span>
        </Flex>
      ),
      icon: null,
      width: 500,
      closable: true,
      footer: (
        <Flex justify="center" gap={10} className="tw-mt-6">
          <Button
            className="btn-danger"
            onClick={() => {
              onDelete();
              modalInstance.destroy();
            }}
          >
            Yes
          </Button>
          <Button onClick={() => modalInstance.destroy()} className="btn-primary">
            No
          </Button>
        </Flex>
      )
    });
  };

  return { onConfirmDelete };
};
