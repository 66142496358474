import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import useMobileWindow from "hooks/useMobileWindow";

import { Flex } from "antd";

const SwitchGroup = ({ isSubTask = false, formData, setFormData, permission, taskId, isDisable }) => {
  const isMobile = useMobileWindow();

  return (
    <Flex vertical gap={16} className="tw-mb-12">
      {!isSubTask && (
        <Flex vertical={isMobile} justify="space-between" align="center" gap={16}>
          <Form.Group className="tw-flex tw-justify-between tw-items-center tw-w-full">
            <Form.Label>Priority</Form.Label>
            <label className="switch ms-3">
              <input
                type="checkbox"
                checked={formData.priorityNUM}
                onChange={(e) => setFormData({ ...formData, priorityNUM: e.target.checked })}
                disabled={isDisable}
              />
              <span className="slider round"></span>
            </label>
          </Form.Group>
          <Form.Group className="tw-flex tw-justify-between tw-items-center tw-w-full">
            <Form.Label>Current</Form.Label>
            <label className="switch ms-3">
              <input
                type="checkbox"
                checked={formData.task_currentYN}
                onChange={(e) => setFormData({ ...formData, task_currentYN: e.target.checked })}
                disabled={taskId === null || permission === "Observer"}
              />
              <span className="slider round"></span>
            </label>
          </Form.Group>
        </Flex>
      )}
      <Flex vertical={isMobile} justify="space-between" align="center" gap={16}>
        <Form.Group className="tw-flex tw-justify-between tw-items-center tw-w-full">
          <Form.Label>Actioned</Form.Label>
          <label className="switch ms-3">
            <input
              type="checkbox"
              checked={formData.task_actionedYN}
              onChange={(e) => setFormData({ ...formData, task_actionedYN: e.target.checked })}
              disabled={taskId === null || permission === "Observer"}
            />
            <span className="slider round"></span>
          </label>
        </Form.Group>
        <Form.Group className="tw-flex tw-justify-between tw-items-center tw-w-full">
          <Form.Label>Task Closed/Completed</Form.Label>
          <label className="switch ms-3">
            <input
              type="checkbox"
              checked={formData.task_closedYN}
              onChange={(e) => setFormData({ ...formData, task_closedYN: e.target.checked })}
              disabled={isDisable}
            />
            <span className="slider round"></span>
          </label>
        </Form.Group>
      </Flex>
    </Flex>
  );
};

export default SwitchGroup;

SwitchGroup.propTypes = {
  isSubTask: PropTypes.bool,
  taskId: PropTypes.string,
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  permission: PropTypes.string,
  isDisable: PropTypes.bool
};
