import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Badge, Flex } from "antd";
import "./Task.css";
import { HambugurMenuContext } from "services/contexts";
import { MdDragIndicator, MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import compareAsc from "date-fns/compareAsc";
import TaskStatusDropdown from "components/TaskStatusDropdown";
import { formatDate } from "services/helpers";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { editProject } from "API/projects";
import { setIsUpdateProject } from "redux/projectSlice";
import useMobileWindow from "hooks/useMobileWindow";
import { useWarningModal } from "hooks/useWarningModal";

const SubTasksList = ({ subTasks, parentTaskId, projectId, onChangeTaskStatus, isPermission }) => {
  // console.log(subTasks, "subtasks-SubTaslsList");
  const onItemSelected = useContext(HambugurMenuContext);
  const dispatch = useDispatch();
  const isMobile = useMobileWindow();
  const { onDisplayError } = useWarningModal();

  const [reorderedSubTasks, setReorderedSubTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setReorderedSubTasks(subTasks);
  }, [subTasks]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEndSubTask = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(subTasks, result.source.index, result.destination.index);

    const formData = {
      project_id: projectId,
      task_id: parentTaskId,
      task_order: items.map((item) => item._id)
    };
    setReorderedSubTasks(items);
    setLoading(true);
    await editProject(formData)
      .then((res) => {
        console.log(res?.data, "res-edit-project with task_order");
        if (res?.data?.error) {
          onDisplayError(res?.data?.error);
        }
        if (res?.data?.success === 1) {
          dispatch(setIsUpdateProject(true));
        }
      })
      .catch((err) => {
        if (err?.response?.status) {
          onDisplayError(err.message);
        }
      })
      .finally(() => setLoading(false));
  };
  // console.log(reorderedSubTasks, "reorderedSubtasks-SubTaslsList");
  return (
    <>
      <CircleSpinnerOverlay loading={loading} message="Saving..." color="#5e6e82" overlayColor="rgba(0,0,0,0.3)" />
      <DragDropContext onDragEnd={onDragEndSubTask}>
        <Droppable droppableId={`droppable-sub-${parentTaskId}`}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {reorderedSubTasks.length > 0 &&
                reorderedSubTasks.map((subTask, index) => (
                  <Draggable key={subTask._id} draggableId={subTask._id} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div className="d-flex align-items-center">
                          <div
                            className={
                              subTask.task_closedYN === "1"
                                ? "actioned-task"
                                : compareAsc(new Date(), new Date(subTask.date_deadline)) > 0
                                ? "overdue-task"
                                : subTask.task_actionedYN === "1"
                                ? "actioned-task"
                                : "task-item"
                            }
                            onClick={() => onItemSelected("Edit Sub-task", subTask._id, parentTaskId)}
                            style={{
                              marginLeft: isMobile ? "16px" : "65px",
                              width: "100%"
                            }}
                          >
                            <div className="d-flex align-items-center px-1 py-3">
                              {isPermission && (
                                <div className="d-flex justify-content-center align-items-center me-2 grab" {...provided.dragHandleProps}>
                                  <MdDragIndicator />
                                </div>
                              )}
                              <MdOutlineSubdirectoryArrowRight style={{ fontSize: "18px" }} />
                              <div onClick={(e) => e.stopPropagation()}>
                                <TaskStatusDropdown
                                  taskClosed={subTask.task_closedYN}
                                  onChangeTaskStatus={() => onChangeTaskStatus(subTask._id, subTask.name, subTask.task_closedYN)}
                                  isDropMenu={
                                    subTask.permission === "Owner" ||
                                    subTask.permission === "Admin" ||
                                    subTask.permission === "Project Manager"
                                  }
                                />
                              </div>
                              <div className="subtaskWrapper p-0">
                                <div className="subtaskName ms-2">
                                  <span
                                    title={`TK(${subTask._id}): ${subTask.name}`}
                                    className="ellipsis"
                                  >{`TK(${subTask._id}): ${subTask.name}`}</span>
                                </div>
                                {!isMobile && (
                                  <div className="subtaskRightBlock">
                                    <Flex gap={4} className="subtaskTagsBlock">
                                      {subTask.task_tags
                                        .slice(0, 2)
                                        .map(
                                          ({ tag_color, tag_name, task_tag_link_id }) =>
                                            tag_color && <Badge count={tag_name} color={tag_color} key={task_tag_link_id} />
                                        )}
                                    </Flex>
                                    <div className="d-flex subtaskActionerDate">
                                      <div className="d-flex flex-column teamMember">
                                        <div className="d-flex flex-column">
                                          {subTask.actioners?.length > 0
                                            ? subTask.actioners.map((el) => {
                                                return <span key={el._id}>{el.name}</span>;
                                              })
                                            : ""}
                                        </div>
                                        {subTask.task_actionedYN === "1" && <Badge count="Actioned" color="#A5C6F2" />}
                                      </div>
                                      <div className="dateValue">
                                        {subTask.date_deadline === null ? "" : formatDate(subTask.date_deadline)}
                                      </div>
                                      <div className="priority"></div>
                                      {isPermission && <div className="hamburgerDropdownBlock"></div>}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="task-hr"
                          style={{
                            marginLeft: "65px"
                          }}
                        ></div>
                      </div>
                    )}
                  </Draggable>
                ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

SubTasksList.propTypes = {
  subTasks: PropTypes.array.isRequired,
  parentTaskId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  onChangeTaskStatus: PropTypes.func,
  isPermission: PropTypes.bool
};
export default SubTasksList;
