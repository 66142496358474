import React, { useEffect, useState } from "react";
import "features/styles.css";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { Flex, Button } from "antd";

import DocumentsLinks from "components/DocumentsLinks";
import Timeline from "components/ChronoTimeline";
import FieldsListView from "components/FieldListView";
import TagMultiSelect from "components/TagMultiSelect";
import Message from "./Message";
import Status from "./Status";
import { getParticipants } from "API/participants";
import { addTask, editTask, getTask } from "API/tasks";
import { setIsUpdateProject } from "redux/projectSlice";
import ButtonSpinner from "components/ButtonSpinner";
import { formatDate, isJSONRegExp } from "services/helpers";
import DraftEditor from "components/DraftEditor";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import useTabletWindow from "hooks/useTabletWindow";
import { dispatcOpenTaskLoading, dispatchIsAlerts } from "redux/taskSlice";
import useMobileWindow from "hooks/useMobileWindow";
import TaskDetailRightSidebar from "features/task/TaskDetailRightSidebar";
import { parseEmailData, loadContent, saveContent } from "./helpers";
import MultiSelect from "components/MultiSelect";
import SwitchGroup from "features/task/forms/SwitchGroup";
import { useModalActions } from "features/task/hooks/useModalActions";
import DatePickerGroup from "features/task/forms/DatePickerGroup";
import DescLoadingShimmer from "features/task/forms/DescLoadingShimmer";
import { useWarningModal } from "hooks/useWarningModal";

const TaskDetail = ({ onClose, id, projectId }) => {
  const { onDisplayError } = useWarningModal();

  const isTablet = useTabletWindow();
  const isMobile = useMobileWindow();

  const projects = useSelector((state) => state.workspaces.projects);
  const project = useSelector((state) => state.project.project);
  const { permission, name: projectName, date_from, date_to } = project;

  const projectDateFrom = formatDate(date_from);
  const projectDateTo = formatDate(date_to);
  const dispatch = useDispatch();
  const today = formatDate(new Date());
  const initialFormData = {
    name: "",
    detailsISsmallplaintextbox: EditorState.createEmpty(),
    internal_detailsISsmallplaintextbox: EditorState.createEmpty(),
    task_owner_id: "0",
    priorityNUM: false,
    task_currentYN: false,
    task_actionedYN: false,
    task_closedYN: false,
    date_from: today,
    date_to: today,
    date_deadline: today
  };
  const [formData, setFormData] = useState(initialFormData);

  const [isSubTask, setIsSubTask] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState("");
  const [taskFields, setTaskFields] = useState([]);

  const [taskTags, setTaskTags] = useState([]);
  const [taskTagsList, setTaskTagsList] = useState([]);

  const [loggerName, setLoggerName] = useState("");
  const [addedDate, setAddedDate] = useState("");
  const [closedDate, setClosedDate] = useState("");

  const [observers, setObservers] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [actionerIds, setActionersIds] = useState([]);
  const [fullParticipantsData, setFullParticipantsData] = useState([]);
  const [selecetedActioners, setSelecetedActioners] = useState([]);

  const [timelines, setTimelines] = useState([]);

  const [currentTaskId, setCurrentTaskId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errorName, setErrorName] = useState("");
  const [isDocumentsLinks, setIsDocumentsLinks] = useState(true);
  const [isSpinnerLoad, setIsSpinnerLoad] = useState(false);
  const [taskPermission, setTaskPermission] = useState(null);

  useEffect(() => {
    if (id !== null) {
      initTaskData(id);
      setCurrentTaskId(id);
    }
  }, [id]);

  const initTaskData = async (id) => {
    try {
      setLoading(true);
      const response = await getTask(id);
      if (response.data.success === 1) {
        await setParticipantsAsync();
        const task = response.data.task;
        console.log(task, "task-initTaskData");
        dispatch(dispatchIsAlerts(task?._id));
        setTaskPermission(task.permission);
        setIsSubTask(Boolean(task.parent_task_id));
        setCurrentProjectId(task.projectISbb_t925_projectsID);
        setTimelines(task.timeline);

        const parsedDescriptionData =
          task.detailsISsmallplaintextbox !== null && isJSONRegExp(task.detailsISsmallplaintextbox)
            ? loadContent(task.detailsISsmallplaintextbox)
            : task.detailsISsmallplaintextbox !== null
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(parseEmailData(task.detailsISsmallplaintextbox)))
              )
            : EditorState.createEmpty();

        const parsedInternalDescription =
          task.internal_detailsISsmallplaintextbox !== null && isJSONRegExp(task.internal_detailsISsmallplaintextbox)
            ? loadContent(task.internal_detailsISsmallplaintextbox)
            : task.internal_detailsISsmallplaintextbox !== null
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(convertFromHTML(parseEmailData(task.internal_detailsISsmallplaintextbox)))
              )
            : EditorState.createEmpty();

        setFormData({
          name: task?.name,
          detailsISsmallplaintextbox: parsedDescriptionData,
          internal_detailsISsmallplaintextbox: parsedInternalDescription,
          task_owner_id: task.task_owner_id === null ? "0" : task.task_owner_id,
          priorityNUM: !!parseFloat(task.priorityNUM),
          task_currentYN: !!parseFloat(task.task_currentYN),
          task_actionedYN: !!parseFloat(task.task_actionedYN),
          task_closedYN: !!parseFloat(task.task_closedYN),
          date_from: task.date_from === null ? today : formatDate(task.date_from),
          date_to: task.date_to === null ? today : formatDate(task.date_to),
          date_deadline: task.date_deadline === null ? "" : formatDate(task.date_deadline)
        });

        if (task.actioners.length > 0) {
          const actionerNames = task.actioners.map((item) => item.name);
          setSelecetedActioners(actionerNames);
          setActionersIds(task.actioners.map((item) => item._id));
        }

        const taskFieldsList = task.task_fields_list;
        setTaskFields(taskFieldsList === null ? [] : taskFieldsList.reverse());
        const taskTagsList = task.task_tags_list;
        setTaskTagsList(taskTagsList === null ? [] : taskTagsList);
        setTaskTags(task.task_tags === null ? [] : task.task_tags);

        setLoggerName(task.logger_name === null ? "" : task.logger_name);
        setAddedDate(task._dateadded === null ? "" : formatDate(task._dateadded));
        setClosedDate(task.datetime_closed === null ? "" : formatDate(task.datetime_closed));
      } else if (response?.data?.error) {
        onDisplayError(response.data.error);
      }
      setLoading(false);
      dispatch(dispatcOpenTaskLoading(false));
    } catch (err) {
      onDisplayError(err.toString());
    }
  };

  const setParticipantsAsync = async () => {
    // console.log(actioners,'actioners-setPareticipantsAsync')
    await getParticipants(null, projectId)
      .then((res) => {
        if (res?.data?.error) {
          onDisplayError(res.data.error);
        }
        const participantsData = res?.data?.list;
        setObservers(participantsData?.filter((item) => item.type === "Observer" && item.account_id !== null));
        const fullArrParticipants = participantsData?.filter((item) => item.type !== "Observer" && item.account_id !== null);
        setParticipants(fullArrParticipants.map((el) => el.account_name));
        setFullParticipantsData(fullArrParticipants);
      })
      .catch((err) => {
        onDisplayError(err?.message);
      });
  };

  const onDoneTask = async () => {
    if (loading) {
      onClose();
      document.body.classList.add("overflow-auto");
      document.body.classList.remove("overflow-hidden");
    }
    if (currentTaskId && formData?.name === "") {
      setErrorName("The Title field is required");
      return;
    }
    const fData = {
      ...formData,
      task_id: currentTaskId,
      detailsISsmallplaintextbox: saveContent(formData?.detailsISsmallplaintextbox),
      internal_detailsISsmallplaintextbox: saveContent(formData?.internal_detailsISsmallplaintextbox),
      projectISbb_t925_projectsID: currentProjectId,
      actioner_id: actionerIds
    };

    if (currentTaskId) {
      setIsSpinnerLoad(true);
      await editTask(fData)
        .then((res) => {
          console.log(res, res.data, "edit-task");
          if (res?.data?.error) {
            onDisplayError(res?.data?.error);
            return;
          }
          dispatch(setIsUpdateProject(true));
        })
        .catch((err) => {
          if (err?.response?.status) {
            onDisplayError(err.response.data.error);
          }
        })
        .finally(() => setIsSpinnerLoad(false));
    }
    onClose();
    document.body.classList.add("overflow-auto");
    document.body.classList.remove("overflow-hidden");
  };

  const addNewTask = async () => {
    if (formData.name === "") {
      setErrorName("The Title field is required");
      return;
    }
    if (currentTaskId === null) {
      await addTask({ ...formData, project_id: projectId })
        .then((res) => {
          console.log(res, res.data, "add-tsk");
          if (res?.data?.error) {
            onDisplayError(res?.data?.error);
            return;
          }
          const newTaskId = res.data.new_task_id.toString();
          setCurrentTaskId(newTaskId);
          initTaskData(newTaskId);
          dispatch(setIsUpdateProject(true));
          setErrorName("");
        })
        .catch((err) => {
          if (err?.response?.status) {
            onDisplayError(err.response.data.error);
          }
        });
    }
  };

  const onActionerToChanged = (data) => {
    setSelecetedActioners(data.map((el) => el.value));
    const accountIds = fullParticipantsData
      .filter((item) => data.some((option) => option.value === item.account_name))
      .map((item) => item.account_id);
    setActionersIds(accountIds);
  };

  const { onCloseWithoutSaving, onDeleteTask } = useModalActions(onClose);

  const isPermission = permission === "Owner" || permission === "Admin" || permission === "Project Manager";

  const isDisable = currentTaskId === null || !isPermission;

  const title = id === null ? "New Task" : isPermission ? `Edit Task TK(${id})` : `View Task TK(${id})`;

  return (
    <>
      <div className="modal-container" onClick={onCloseWithoutSaving}>
        <div
          className="modal-content"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Form>
            <div className="formWrapper" style={{ minHeight: "100%" }}>
              <div className="px-4 bg-white mainBlock">
                <div className="bg-white py-4 d-flex flex-column flex-md-row gap-2 justify-content-between align-items-center">
                  <span className="fs-5 fw-bold">{title}</span>
                  <Flex align="center">
                    {id && (
                      <Button size="sm" onClick={onCloseWithoutSaving} className="me-2 btn-warning">
                        Close
                      </Button>
                    )}
                    <ButtonSpinner onClick={onDoneTask} loading={isSpinnerLoad}>
                      Done
                    </ButtonSpinner>
                    {(isMobile || isTablet) && isPermission && (
                      <Button
                        className="ms-2 btn-sm btn-outline-danger"
                        onClick={() => onDeleteTask(currentTaskId)}
                        style={{ float: "right" }}
                        disabled={isDisable}
                      >
                        Delete
                      </Button>
                    )}
                  </Flex>
                </div>
                <div className="nameBlock mb-5">
                  <Form.Group className="nameInput">
                    <>
                      <Form.Label>Title</Form.Label>
                      {loading ? (
                        <div className="animated-background mx-2"></div>
                      ) : (
                        <Form.Control
                          type="text"
                          placeholder="Task title"
                          value={formData?.name}
                          onChange={(e) => {
                            if (e.target.value.length !== 0) setErrorName(null);
                            setFormData({ ...formData, name: e.target.value });
                          }}
                          onBlur={addNewTask}
                          className={errorName ? "border-danger" : ""}
                          disabled={currentTaskId && !isPermission}
                          style={{
                            backgroundColor: !isPermission ? "transparent" : ""
                          }}
                        />
                      )}
                      {errorName && (
                        <div className="text-danger" style={{ fontSize: "12px" }}>
                          {errorName}
                        </div>
                      )}
                    </>
                  </Form.Group>
                </div>
                <Form.Group className="mb-5" controlId="taskDescription">
                  <>
                    <Form.Label>Description</Form.Label>
                    {loading ? (
                      <DescLoadingShimmer />
                    ) : (
                      <>
                        <DraftEditor
                          value={formData?.detailsISsmallplaintextbox}
                          onDescriptionChange={(data) => setFormData({ ...formData, detailsISsmallplaintextbox: data })}
                          isDisable={currentTaskId === null}
                          isReadOnly={!isPermission}
                        />
                      </>
                    )}
                  </>
                </Form.Group>

                {(permission !== "Observer" ||
                  (currentTaskId === null && permission === "Observer") ||
                  (taskPermission === "Owner" && permission === "Observer")) && (
                  <Form.Group className="mb-5">
                    <>
                      <Form.Label>Internal Description</Form.Label>
                      {loading ? (
                        <DescLoadingShimmer />
                      ) : (
                        <DraftEditor
                          value={formData?.internal_detailsISsmallplaintextbox}
                          onDescriptionChange={(data) => setFormData({ ...formData, internal_detailsISsmallplaintextbox: data })}
                          isDisable={currentTaskId === null}
                          isReadOnly={!isPermission}
                        />
                      )}
                    </>
                  </Form.Group>
                )}
                {loading ? (
                  <div className="d-flex flex-column my-2 w-100">
                    <Form.Label>Project</Form.Label>
                    <div className="animated-background mx-2"></div>
                  </div>
                ) : (
                  <Form.Group className="w-100 me-3 mb-5">
                    <Form.Label>Project</Form.Label>
                    <Form.Select
                      value={currentProjectId}
                      onChange={(e) => setCurrentProjectId(e.target.value)}
                      id="selectId"
                      style={isDisable && currentTaskId !== null ? { backgroundColor: "transparent" } : {}}
                      disabled={isDisable}
                      required
                    >
                      {projects?.map((project) => (
                        <option value={project._id} key={project._id}>
                          {project.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}

                <div className="mb-5">
                  {loading ? (
                    <>
                      <div className="d-flex flex-column my-2 w-50">
                        <Form.Label>Owner/Customer</Form.Label>
                        <div className="animated-background mx-2"></div>
                      </div>
                      <div className="d-flex flex-column my-2 w-50">
                        <Form.Label>Actioners</Form.Label>
                        <div className="animated-background mx-2"></div>
                      </div>
                    </>
                  ) : (
                    <div className="selectsBlock">
                      <Form.Group className="selectItem me-3">
                        <Form.Label>Owner/Customer</Form.Label>
                        <Form.Select
                          value={formData.task_owner_id}
                          onChange={(e) => setFormData({ ...formData, task_owner_id: e.target.value })}
                          id={formData.task_owner_id === "0" || formData.task_owner_id === "" ? "readOnlyPlaceholderId" : "selectId"}
                          style={!isPermission && currentTaskId !== null ? { backgroundColor: "transparent" } : {}}
                          disabled={isDisable}
                          required
                        >
                          <option value="" hidden>
                            Owner/Customer
                          </option>
                          <option value="">None</option>

                          {observers?.map((participant) => (
                            <option value={participant._id} key={participant._id}>
                              {participant.account_name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="selectItem">
                        <Form.Label>Actioners</Form.Label>
                        <MultiSelect
                          onChange={(data) => onActionerToChanged(data)}
                          options={participants}
                          selected={selecetedActioners}
                          placeholder="Actioner"
                          disabled={isDisable}
                          required
                          style={{ height: "36px" }}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>
                {loading ? (
                  <>
                    <div className="animated-background mx-2 my-1" style={{ width: "30%" }}></div>
                    <div className="animated-background mx-2 my-1" style={{ width: "30%" }}></div>
                    <div className="animated-background mx-2 my-1" style={{ width: "30%" }}></div>
                  </>
                ) : (
                  <DatePickerGroup
                    formData={formData}
                    setFormData={setFormData}
                    taskPermission={taskPermission}
                    isDisable={isDisable}
                    projectName={projectName}
                    projectDateFrom={projectDateFrom}
                    projectDateTo={projectDateTo}
                  />
                )}

                {loading ? (
                  <div className="animated-background mx-2"></div>
                ) : taskTags.length > 0 || isPermission ? (
                  <Form.Group className={taskFields.length !== 0 ? "" : "mb-5"}>
                    <>
                      <Form.Label>Tags</Form.Label>
                      <TagMultiSelect
                        taskId={currentTaskId}
                        projectId={projectId}
                        taskTagsList={taskTagsList}
                        savedTags={taskTags}
                        disabled={currentTaskId === null || taskPermission === "Actioner" || taskPermission === "Observer"}
                        readOnly={taskPermission === "Actioner" || taskPermission === "Observer"}
                      />
                    </>
                  </Form.Group>
                ) : null}

                {!!taskFields.length && <div className="task-hr my-4"></div>}

                {!!taskFields.length && (
                  <Form.Group>
                    <Form.Label>Fields</Form.Label>
                    <FieldsListView
                      taskId={currentTaskId}
                      projectId={projectId}
                      savedFields={taskFields}
                      disabled={currentTaskId === null}
                      isReadOnly={taskPermission === "Observer"}
                      taskPermission={taskPermission}
                    />
                  </Form.Group>
                )}

                {!!taskFields.length && <div className="task-hr mt-2 mb-4"></div>}
                <SwitchGroup
                  isSubTask={false}
                  formData={formData}
                  setFormData={setFormData}
                  permission={permission}
                  taskId={currentTaskId}
                  isDisable={isDisable}
                />

                <Status
                  taskId={currentTaskId}
                  loggerName={loggerName}
                  addedDate={addedDate}
                  closedDate={closedDate}
                  permission={permission}
                  isSubTask={isSubTask}
                />

                <Form.Group className="w-100 my-5">
                  <Form.Label>Notes & Messages</Form.Label>
                  <Message taskId={currentTaskId} />
                </Form.Group>

                {(isMobile || isTablet) && (isPermission || isDocumentsLinks) && (
                  <Form.Group className="mb-5">
                    <Form.Label>Documents & Links</Form.Label>
                    <DocumentsLinks
                      isDisabled={currentTaskId === null}
                      taskId={currentTaskId}
                      setIsDocumentsLinks={setIsDocumentsLinks}
                      isPermission={isPermission}
                    />
                  </Form.Group>
                )}

                {!isMobile && timelines?.length > 0 && (
                  <Form.Group className="mb-5 w-100 timelineHorizontal">
                    <Form.Label className="mb-3">Timeline</Form.Label>
                    <Timeline values={timelines} mode="HORIZONTAL" />
                  </Form.Group>
                )}
              </div>
              <div className="bg-gray opacity-75" style={{ width: "10px" }}></div>
              <TaskDetailRightSidebar
                permission={project?.permission}
                timelines={timelines}
                isDocumentsLinks={isDocumentsLinks}
                setIsDocumentsLinks={setIsDocumentsLinks}
                loading={loading}
                onDeleteTask={() => onDeleteTask(currentTaskId)}
                id={id}
              />
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

TaskDetail.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  projectId: PropTypes.string
};
export default TaskDetail;
