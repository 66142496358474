import { useState } from "react";
import { useSelector } from "react-redux";
import { getCalendar } from "API/calendar";
import { useWarningModal } from "hooks/useWarningModal";

export const useCalendarAPI = () => {
    const workspace = useSelector((state) => state.workspaces.workspace);
  const workspaceId = workspace?._id;
  const { onDisplayError } = useWarningModal();
  const [loading, setLoading] = useState(false);

  const getCalendarData = async (date_start=null, date_end=null) => {
    setLoading(true);
    try {
      const res = await getCalendar(date_start, date_end, workspaceId );
      if (res?.data?.error) {
        onDisplayError(res.data.error);
        return null; 
      }

      const result = res?.data?.result;
      return result || null; 
    } catch (err) {
      if (err?.response?.status === 401) {
        window.location.href = "https://account2.task925.com/?killsession";
      } else {
        onDisplayError(err.message);
      }
      return null; 
    } finally {
        setLoading(false); 
      }
  };

  return { getCalendarData, loading };
};

  //   const getCalendarData = async () => {
  //     await getCalendar()
  //       .then((res) => {
  //         console.log(res, "res-calendar");
  //         if (res?.data?.error) {
  //           onDisplayError(res.data.error);
  //           return;
  //         }
  //         const result = res?.data?.result;
  //         console.log(result, 'result-in HOOK')
  //         if (result) {
  //           return result
  //         }
  //       })
  //       .catch((err) => {
  //         if (err?.response?.status === 401) {
  //           window.location.href = "https://account2.task925.com/?killsession";
  //         } else onDisplayError(err.message);
  //       });
  //   };
