import { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Button, Spin } from "antd";

const AlertsList = ({ unreadAlerts, seenAlerts, onMarkAsSeen, isMarkAsSeenLoading }) => {
  const [selectedAlerts, setSelectedAlerts] = useState(new Set());
  const [isAllSelected, setisAllSelected] = useState(false);

  const handleSelectAll = (e) => {
    setisAllSelected(e.target.checked);
    const newSelected = e.target.checked ? new Set(unreadAlerts.map((notif) => notif._id)) : new Set();
    setSelectedAlerts(newSelected);
  };

  const handleSelectOne = (id) => {
    setSelectedAlerts((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      setisAllSelected(newSet.size === unreadAlerts.length);
      return newSet;
    });
  };

  if (isMarkAsSeenLoading) {
    return (
      <Flex justify="center" align="center" className="tw-w-full tw-h-20">
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <div className="tw-space-y-2">
      {unreadAlerts.length > 0 && (
        <Flex justify="space-between" className="tw-h-10 tw-pt-2 tw-px-2">
          <Flex className="tw-flex tw-items-center tw-gap-2">
            <input type="checkbox" checked={isAllSelected} onChange={handleSelectAll} className="tw-cursor-pointer" />
            <label className="tw-font-semibold tw-text-sm">Mark as seen</label>
          </Flex>
          {selectedAlerts.size > 0 && (
            <Button onClick={() => onMarkAsSeen(Array.from(selectedAlerts))} className="ocean-color-button">
              Confirm marking
            </Button>
          )}
        </Flex>
      )}

      <ul className="tw-divide-y tw-divide-gray-200 tw-m-0 tw-p-0">
        {unreadAlerts.map((notif) => (
          <li key={notif._id} className="tw-flex tw-items-center tw-p-2 tw-text-gray-700 hover:tw-bg-gray-100 tw-font-semibold">
            <input
              type="checkbox"
              checked={selectedAlerts.has(notif._id)}
              onChange={() => handleSelectOne(notif._id)}
              className="tw-mr-2 tw-cursor-pointer tw-mt-1"
            />
            <span onClick={() => handleSelectOne(notif._id)} className="tw-text-sm">
              {notif.alertISsmallplaintextbox}
            </span>
          </li>
        ))}
      </ul>

      <ul className="tw-divide-y tw-divide-gray-200 tw-m-0 tw-p-0">
        {seenAlerts.map((notif) => (
          <li key={notif._id} className="tw-p-2 tw-text-gray-700 hover:tw-bg-gray-100 tw-text-sm">
            {notif.alertISsmallplaintextbox}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AlertsList;

AlertsList.propTypes = {
  unreadAlerts: PropTypes.array.isRequired,
  seenAlerts: PropTypes.array.isRequired,
  onMarkAsSeen: PropTypes.func.isRequired,
  isMarkAsSeenLoading: PropTypes.bool
};
