import React from "react";
import PropTypes from "prop-types";

const Footer = ({ text }) => {
  return (
    <div className="container text-center my-3">
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
      <div>V1.2.60</div>
    </div>
  );
};
Footer.propTypes = {
  text: PropTypes.string
};
export default Footer;
