import React from "react";
import PropTypes from "prop-types";
import { Flex } from "antd";
import { Modal, Button } from "react-bootstrap";
import { FaExclamationTriangle } from "react-icons/fa";

const ConfirmModal = ({ isShow, onDelete, onClose, title, message, children }) => {
  return (
    <Modal show={isShow} onHide={() => onClose(false)}>
      <Modal.Header closeButton className="d-flex justify-content-center">
        <div className="w-100 text-center position-relative">
          <Modal.Title>{title}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="py-5">
        {(children || message) && (
          <Flex justify="center" align="center" gap={4}>
            <span className="tw-w-5">
              <FaExclamationTriangle className="text-warning" />
            </span>
            <span>{children ? children : message}</span>
          </Flex>
        )}
        {children ? null : (
          <Flex justify="center" className="tw-mt-6" gap={16}>
            <Button variant="primary" style={{ minWidth: 70 }} onClick={onDelete}>
              Yes
            </Button>
            <Button variant="secondary" className="btn-sm" style={{ minWidth: 70 }} onClick={() => onClose(false)}>
              No
            </Button>
          </Flex>
        )}
      </Modal.Body>
    </Modal>
  );
};
ConfirmModal.propTypes = {
  isShow: PropTypes.bool,
  onDelete: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node
};

export default ConfirmModal;
