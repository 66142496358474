import React from "react";
import classNames from "classnames";
import { ACCOUNT_URL } from "services/constants";
import { Image, Dropdown } from "react-bootstrap";
import { logout } from "redux/authSlice";
import { getLogout } from "API/session";
import { useSelector, useDispatch } from "react-redux";

const LogoutDropdown = () => {
  const { name, email } = useSelector((state) => state.appsetting.account);
  const appSettingLoad = useSelector(
    (state) => state.appsetting.app_setting_load
  );
  const dispatch = useDispatch();

  const onLogoutClick = async () => {
    await getLogout();
    dispatch(logout());
    window.location.href = "https://account2.task925.com/?killsession";
    // navigate("/login");
  };
  return (
    <Dropdown align="end" className="account-icon">
      <Dropdown.Toggle variant="outline-light" className="tw-w-6 px-0">
        <Image
          rounded
          src="/imgs/userdefault.png"
          className={classNames("tw-w-full", {"rotate-image": appSettingLoad})}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.ItemText className="fw-bold">{name}</Dropdown.ItemText>
        <Dropdown.ItemText>{email}</Dropdown.ItemText>
        <Dropdown.Divider />
        <Dropdown.Item href={ACCOUNT_URL}>Your Account</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={onLogoutClick}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default LogoutDropdown;
