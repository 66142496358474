import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Navbar from "layouts/Navbar";
import WorkspaceList from "features/workspace/WorkspaceList";
import Login from "features/auth/Login";
import "./App.css";
import Dashboard from "features/dashboard/Dashboard";
import Reports from "features/reports/Reports";
import FiltersList from "features/reports/FiltersList";
// import Members from "features/members/Members";
// import Calendar from "features/calendar";

import LoadingScreen from "layouts/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { getAppSettingData } from "API/settings";
import { getSession } from "API/session";
import { init } from "redux/appsettingSlice";
import { setDisplayWorkspace, setDisplayProject, fetchWorkspaces } from "redux/workspaceSlice";
import { setSessionData } from "redux/authSlice";
import { setProjectsList } from "redux/projectSlice";
import Footer from "layouts/footer";
import { useWarningModal } from "hooks/useWarningModal";

function App() {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onDisplayError } = useWarningModal();

  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    load();
    localStorage.removeItem("userInteracted");
  }, []);

  const load = async () => {
    await getAppSettingData()
      .then((res) => {
        // console.log(res.data, "app-settings-res");
        if (res?.data?.alert_action_url === "/login") {
          onDisplayError(res?.data?.error);
          navigate("/login");
          return;
        }
        const settingsData = res.data.app_settings;
        dispatch(init(settingsData));
        dispatch(fetchWorkspaces(settingsData.workspaces));

        dispatch(setProjectsList(settingsData.projects));
        dispatch(
          setDisplayWorkspace({
            display_workspace: settingsData.display_workspace ? settingsData.display_workspace : settingsData.workspaces[0]._id
          })
        );
        dispatch(setDisplayProject({ display_project: settingsData.display_project }));
      })
      .catch((err) => {
        onDisplayError(err?.message);
      });

    await getSession()
      .then((res) => {
        // console.log(res, res.data, "session-app");
        dispatch(setSessionData(res.data));
        navigate(location?.pathname);
        setIsLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err, err.response.status, process.env.NODE_ENV, "session-app-err");
          if (process.env.NODE_ENV === "production") {
            window.location.href = "https://account2.task925.com/?killsession";
          } else {
            navigate("/login");
          }
          setIsLoader(false);
        } else {
          onDisplayError(err?.message);
        }
      });
  };

  if (isLoader) {
    return <LoadingScreen />;
  }
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        <div style={{ flex: 1 }}>
          {location.pathname !== "/login" && <Navbar />}
          <Routes>
            <Route path="/" element={<WorkspaceList />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/filters" element={<FiltersList />} />
            <Route path="/filtered-tasks" element={<Reports />} />
            {/* <Route path="/team" element={<Members />} />
            <Route path="/calendar" element={<Calendar />} /> */}

            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
