import React, { useState, useEffect, useContext } from "react";
import styles from "./Workspace.module.scss";
import PropTypes from "prop-types";
import { Card, Button, Dropdown } from "react-bootstrap";
import { Flex } from "antd";
import { useSelector } from "react-redux";
import HambugerDropdown from "components/HambugerDropdown";
import ProjectList from "features/project/ProjectList";
import ProjectMonth from "features/project/ProjectMonth";
import ProjectTimeline from "features/project/ProjectTimeline";
import { WORKSPACE_MENUITEMS } from "services/constants";
import { HambugurMenuContext } from "services/contexts";
import useMobileWindow from "hooks/useMobileWindow";
import Members from "features/members/Members";
import Calendar from "features/calendar";

const WorkspaceSingle = () => {
  const isMobile = useMobileWindow();

  const noProjectsMessageForOwnerAndAdmin = 'This workspace has no projects. Click "New Project" above to add one.';
  const noProjectsMessageForOther = "This workspace has no projects.";

  const workspace = useSelector((state) => state.workspaces.workspace);
  const projects = useSelector((state) => state.workspaces.projects);
  const { permission } = workspace;
  const isPermission = permission === "Owner" || permission === "Admin" || permission === "Project Manager";
  const isOnlyOwnerPermission = permission === "Owner" || permission === "Admin";

  const onItemSelected = useContext(HambugurMenuContext);
  const [viewStatus, setViewStatus] = useState("list");

  useEffect(() => {
    setViewStatus("list");
  }, [projects]);
  console.log(workspace, "workspace");
  return (
    <>
      <div className={styles.workspaceHeader}>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {isOnlyOwnerPermission && (
              <div className="tw-mr-4">
                <HambugerDropdown
                  menuItems={WORKSPACE_MENUITEMS}
                  itemId={workspace?._id}
                  parentId={null}
                  isExistedElements={!!projects.length}
                />
              </div>
            )}
            <span className={`${styles.workspaceTitle}`}>{workspace?.name}</span>
          </div>
          {isPermission ? (
            <Flex align="center" gap={8}>
              <Flex gap={8}>
                {workspace.permission !== "Project Manager" && (
                  <Button size="sm" className="action-btn" onClick={() => onItemSelected("New Project", workspace?._id)}>
                    + New Project
                  </Button>
                )}
                {/* <Button size="sm" className="action-btn" onClick={() => setViewStatus("team")}>
                  My team
                </Button>
                <Button size="sm" className="action-btn" onClick={() => setViewStatus("calendar")}>
                  My calendar
                </Button> */}
              </Flex>
              {!isMobile && (
                <Dropdown align="end">
                  <Dropdown.Toggle className="px-0 docsToggle">View</Dropdown.Toggle>
                  <Dropdown.Menu onClick={(e) => e.stopPropagation()} className="docsDropdownMenu">
                    <Dropdown.Item onClick={() => setViewStatus("list")}>List</Dropdown.Item>
                    <Dropdown.Item onClick={() => setViewStatus("list")}>My team</Dropdown.Item>
                    <Dropdown.Item onClick={() => setViewStatus("calendar")}>My calendar</Dropdown.Item>
                    <Dropdown.Item onClick={() => setViewStatus("month")}>Month</Dropdown.Item>
                    <Dropdown.Item onClick={() => setViewStatus("timeline")}>Timeline</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Flex>
          ) : workspace.permission !== "Observer" ? (
            <Dropdown align="end">
              <Dropdown.Toggle className="px-0 docsToggle">View</Dropdown.Toggle>
              <Dropdown.Menu onClick={(e) => e.stopPropagation()} className="docsDropdownMenu">
                <Dropdown.Item onClick={() => setViewStatus("list")}>List</Dropdown.Item>
                {/* <Dropdown.Item onClick={() => setViewStatus("list")}>My team</Dropdown.Item> */}
                <Dropdown.Item onClick={() => setViewStatus("calendar")}>My calendar</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null
          // <Flex gap={8}>
          //   <Button size="sm" className="action-btn" onClick={() => setViewStatus("team")}>
          //     My team
          //   </Button>
          //   <Button size="sm" className="action-btn" onClick={() => setViewStatus("calendar")}>
          //     My calendar
          //   </Button>
          // </Flex>
          }
        </div>
      </div>
      <Card className="show" style={{ backgroundColor: "var(--background-block)" }}>
        <Card.Body className={styles.workspaceCardBody}>
          <>
            {projects?.length || viewStatus === "calendar" ? (
              <>
                {viewStatus === "list" ? (
                  <ProjectList workspaceId={workspace?._id} />
                ) : viewStatus === "team" ? (
                  <Members workspaceId={workspace?._id} />
                ) : viewStatus === "calendar" ? (
                  <Calendar workspaceId={workspace?._id} />
                ) : viewStatus === "month" ? (
                  <ProjectMonth projects={projects} workspaceId={workspace?._id} />
                ) : (
                  <ProjectTimeline projects={projects} workspaceId={workspace?._id} />
                )}
              </>
            ) : isPermission ? (
              <div className="message">{noProjectsMessageForOwnerAndAdmin}</div>
            ) : (
              <div className="message">{noProjectsMessageForOther}</div>
            )}
          </>
        </Card.Body>
      </Card>
    </>
  );
};

WorkspaceSingle.propTypes = {
  workspace: PropTypes.object
};

export default WorkspaceSingle;
