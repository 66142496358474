import axios from "./axios";

export const getCalendar = (date_start, date_end, workspace_id ) => {
  const params = new URLSearchParams();
  if (date_start) params.append("date_start", date_start);
  if (date_end) params.append("date_end", date_end);
  if (workspace_id) params.append("workspace_id", workspace_id);


  return axios.get("app/get_calendar", {
    params
  });
};

export const getActiveLeaveDaysRequests = (status = null, workspace_id=null, page = null) => {
  const params = new URLSearchParams();
  if (status) params.append("status", status);
  if (workspace_id) params.append("workspace_id", workspace_id);
  if (page) params.append("page", page);

  return axios.get("app/get_active_requests", {
    params
  });
};

export const addLeaveRequest = (payload) => axios.post("app/add_leave_request", payload);

export const editLeaveRequest = (payload) => axios.patch("app/edit_leave_request", payload);

export const addSpecaialDays = (payload) => axios.post("app/add_special_days", payload);

export const editSpecialDay = (payload) => axios.patch("app/edit_special_day", payload);

export const deleteSpecialDay = (payload) => axios.delete('app/delete_special_day', {data: payload})
