import PropTypes from "prop-types";
import { App as AntDesignApp, Flex, Table } from "antd";

import { FaEdit } from "react-icons/fa";
import { AiOutlineCheckCircle, AiOutlineClockCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { formatDate } from "services/helpers";
import AddLeaveDaysForm from "features/calendar/AddLeaveDaysForm";

const LeavesTable = ({ leavesDays = [], isAdmin, adminsOptions, leaveTypes, updateCalendarData, updateBigCalendarData, workspaceId }) => {
  const { modal } = AntDesignApp.useApp();

  const statusIcons = {
    approved: <AiOutlineCheckCircle color="green" />,
    pending: <AiOutlineClockCircle color="orange" />,
    rejected: <AiOutlineCloseCircle color="red" />
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
      fixed: "left",
      width: 200
    },
    {
      title: "Dates",
      key: "dates",
      render: (_, record) => (record.date_start ? `${formatDate(record.date_start)} - ${formatDate(record.date_end)}` : null),
      width: 180
    },
    isAdmin && {
      title: "Admin Status",
      key: "admin_status",
      width: 120,
      render: (_, record) =>
        record.admin_status ? (
          <Flex align="center">
            <span>{statusIcons[record.admin_status]}</span>
            <span className="tw-ml-1 pb-1">{record.admin_status}</span>
          </Flex>
        ) : (
          <span>Not required</span>
        )
    },
    {
      title: "Status",
      key: "status",
      width: 120,
      render: (_, record) => (
        <Flex align="center">
          <span>{statusIcons[record.status]}</span>
          <span className="tw-ml-1 pb-1">{record.status}</span>
        </Flex>
      )
    },
    isAdmin && {
      title: "",
      key: "actions",
      width: 20,
      render: (_, record) => (record._id ? <FaEdit style={{ cursor: "pointer" }} onClick={() => requestLeaveDays(record)} /> : null)
    }
  ].filter(Boolean);

  const requestLeaveDays = (item) => {
    const modalInstance = modal.confirm({
      title: <Flex className="">Approve Leave days</Flex>,
      icon: null,
      content: (
        <Flex>
          <AddLeaveDaysForm
            onClose={() => modalInstance.destroy()}
            adminsOptions={adminsOptions}
            leaveTypes={leaveTypes}
            leaveDayItem={item}
            updateCalendarData={updateCalendarData}
            updateBigCalendarData={updateBigCalendarData}
            isAdmin={isAdmin}
            workspaceId={workspaceId}
          />
        </Flex>
      ),
      width: 800,
      footer: null,
      closable: true
    });
  };

  return (
    <Table
      columns={columns}
      dataSource={[...leavesDays].map((item) => ({ ...item, key: item._id }))}
      scroll={{ x: "max-content" }}
      pagination={false}
      components={{
        header: {
          cell: (props) => <th {...props} style={{ padding: "4px 8px" }} />
        },
        body: {
          cell: (props) => <td {...props} style={{ padding: "6px 8px" }} />
        }
      }}
    />
  );
};

export default LeavesTable;

LeavesTable.propTypes = {
  leavesDays: PropTypes.array,
  isAdmin: PropTypes.bool,
  updateCalendarData: PropTypes.func,
  updateBigCalendarData: PropTypes.func,
  adminsOptions: PropTypes.array,
  leaveTypes: PropTypes.array,
  workspaceId: PropTypes.string
};
