import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Flex, Card, Badge, Divider, Tooltip } from "antd";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import HambugerDropdown from "components/HambugerDropdown";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { editTask } from "API/tasks";
import { editProject } from "API/projects";
import { setIsUpdateProject } from "redux/projectSlice";
import { HambugurMenuContext } from "services/contexts";
import { RiFlag2Fill, RiFlag2Line } from "react-icons/ri";
import { formatDate } from "services/helpers";
import { TASK_MENUITEMS } from "services/constants";
import { FaArrowsAltH } from "react-icons/fa";
import { useWarningModal } from "hooks/useWarningModal";

const TaskKanban = ({ projectTasks, projectId }) => {
  const onItemSelected = useContext(HambugurMenuContext);
  const { onDisplayError } = useWarningModal();

  const { project } = useSelector((state) => state.project);
  const { permission } = project;
  const isPermission = permission === "Owner" || permission === "Admin" || permission === "Project Manager";
  const dispatch = useDispatch();

  const columns = ["assigned", "current", "testing"];
  const columnTitles = ["Assigned", "Current", "Actioned/Testing"];

  const [tasks, setTasks] = useState({
    assigned: [],
    current: [],
    testing: []
  });
  const [maxColumnHeight, setMaxColumnHeight] = useState(10);
  const cardRefs = useRef({});

  useEffect(() => {
    if (projectTasks) {
      const updatedTasks = {
        assigned: [],
        current: [],
        testing: []
      };

      projectTasks.forEach((task) => {
        const taskWithStatus =
          task.task_actionedYN === "0" && task.task_currentYN === "0"
            ? { ...task, status: "assigned" }
            : task.task_actionedYN === "0" && task.task_currentYN === "1"
            ? { ...task, status: "current" }
            : task.task_actionedYN === "1"
            ? { ...task, status: "testing" }
            : { ...task, status: "assigned" };

        if (updatedTasks[taskWithStatus.status]) {
          updatedTasks[taskWithStatus.status].push(taskWithStatus);
        }
      });
      Object.keys(updatedTasks).forEach((column) => {
        updatedTasks[column].sort((a, b) => parseFloat(a.kanban_orderNUM) - parseFloat(b.kanban_orderNUM));
      });
      setTasks(updatedTasks);
    }
  }, [projectTasks]);

  useEffect(() => {
    const columnHeightsInPixels = columns.map((col) => {
      return (
        tasks[col]?.reduce((sum, task) => {
          const taskHeight = task.sub_tasks.length > 0 && task.sub_tasks.length <= 3 ? 8 : task.sub_tasks.length > 3 ? 9 : 7;
          return sum + 80 + taskHeight * 10;
        }, 0) || 0
      );
    });
    const maxHeightInPixels = Math.max(...columnHeightsInPixels, 300);

    const maxHeightInRows = Math.ceil(maxHeightInPixels / 50);

    setMaxColumnHeight(maxHeightInRows);
  }, [tasks, columns]);

  const handleDragStart = (e, task, column) => {
    e.dataTransfer.setData("task", JSON.stringify(task));
    e.dataTransfer.setData("fromColumn", column);
  };

  const handleDrop = (e, toColumn) => {
    const taskData = JSON.parse(e.dataTransfer.getData("task"));
    const fromColumn = e.dataTransfer.getData("fromColumn");

    setTasks((prevTasks) => {
      const newTasks = { ...prevTasks };

      if (fromColumn !== toColumn) {
        taskData.status = toColumn;

        newTasks[fromColumn] = newTasks[fromColumn].filter((t) => t._id !== taskData._id);

        newTasks[fromColumn] = newTasks[fromColumn].map((task, index) => ({
          ...task,
          kanban_orderNUM: index
        }));

        if (!newTasks[toColumn]) {
          newTasks[toColumn] = [];
        }

        const newOrder = newTasks[toColumn].length;
        taskData.kanban_orderNUM = newOrder;

        newTasks[toColumn] = [...newTasks[toColumn], taskData];

        newTasks[fromColumn] = [...newTasks[fromColumn]];
        newTasks[toColumn] = [...newTasks[toColumn]];

        updateTask(taskData, toColumn);
        reorderInColumn(newTasks[toColumn].map((task) => task._id));
      }

      return { ...newTasks };
    });
  };

  const updateTask = async (taskData, toColumn) => {
    const formData = {
      task_id: taskData._id,
      name: taskData.name,
      task_currentYN: toColumn === "current" || toColumn === "testing" ? "1" : "0",
      task_actionedYN: toColumn === "testing" ? "1" : "0"
    };

    await editTask(formData)
      .then((res) => {
        if (res.data.error) {
          const message = res.data.error;
          onDisplayError(message);
          return;
        }
        dispatch(setIsUpdateProject(true));
      })
      .catch((err) => {
        if (err) {
          const message = err?.message || err.response?.data?.error;
          onDisplayError(message);
        }
      });
  };

  const onChangePriority = async (e, task) => {
    const formData = {
      task_id: task._id,
      name: task.name,
      priorityNUM: +task.priorityNUM ? 0 : 1
    };
    try {
      await editTask(formData)
        .then(() => {
          dispatch(setIsUpdateProject(true));
        })
        .catch((err) => {
          if (err) {
            const message = err?.message || err?.response?.data?.error;
            onDisplayError(message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleTaskDragStop = (layout, oldItem, newItem, col, itemHeight) => {
    setTasks((prevTasks) => {
      const newTasks = { ...prevTasks };
      const draggedTask = newTasks[col].find((task) => task._id === newItem.i);

      if (draggedTask) {
        newTasks[col] = newTasks[col].filter((task) => task._id !== draggedTask._id);

        const targetIndex = Math.floor(newItem.y / itemHeight);
        const clampedIndex = Math.min(targetIndex, newTasks[col].length);

        newTasks[col].splice(clampedIndex, 0, draggedTask);
      }

      const taskIds = newTasks[col].map((task) => task._id);
      reorderInColumn(taskIds);
      return newTasks;
    });
  };

  const reorderInColumn = async (colTaskIds) => {
    const formData = {
      project_id: projectId,
      name: projectTasks[0]?.project_name,
      task_column_order: colTaskIds
    };

    await editProject(formData)
      .then((res) => {
        if (res?.data?.error) {
          const message = res?.data?.error;
          onDisplayError(message);
        }
        if (res?.data?.success === 1) {
          dispatch(setIsUpdateProject(true));
        }
      })
      .catch((err) => {
        if (err?.response?.status) {
          onDisplayError(err.message);
        }
      });
  };

  return (
    <Flex justify="center" className="tw-flex !tw-w-full !tw-h-full tw-overflow-x-auto tw-p-0">
      <GridLayout
        className="!tw-w-full tw-gap-4"
        rowHeight={50}
        width={columns.length * 440}
        layout={columns.map((col, i) => ({
          i: col,
          x: i,
          y: 0,
          w: 1,
          h: maxColumnHeight,
          static: true
        }))}
        cols={columns.length}
        isDraggable={false}
        isResizable={false}
      >
        {columns.map((col, index) => (
          <Flex key={col} vertical className="tw-w-full tw-min-w-[440px]">
            <Flex className="tw-w-full tw-p-2" onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, col)}>
              <Card title={columnTitles[index]} className={`tw-shadow-md tw-rounded-lg tw-w-full kanban-${col}`}>
                <GridLayout
                  cols={1}
                  width={390}
                  rowHeight={10}
                  isDraggable={true}
                  isResizable={false}
                  layout={tasks[col].map((task, idx) => ({
                    i: task._id,
                    x: 0,
                    y: idx,
                    w: 1,
                    h: task.sub_tasks.length > 0 && task.sub_tasks.length <= 3 ? 8 : task.sub_tasks.length > 3 ? 9 : 7,
                    static: false
                  }))}
                  onDragStop={(layout, oldItem, newItem) => {
                    const itemHeight = newItem.h;
                    handleTaskDragStop(layout, oldItem, newItem, col, itemHeight);
                  }}
                >
                  {tasks[col].map((task) => (
                    <Card
                      key={task._id}
                      ref={(el) => (cardRefs.current[task._id] = el)}
                      className="tw-mb-2 tw-cursor-n-resize task-ant-card"
                      draggable
                      onDragStart={(e) => handleDragStart(e, task, col)}
                    >
                      <Flex vertical gap={2}>
                        <Flex justify="space-between" align="center">
                          <Flex className="react-resizable-handle tw-bg-transparent">
                            <FaArrowsAltH size={24} className="tw-cursor-grab" />{" "}
                          </Flex>
                          <Tooltip title={`TK(${task._id}): ${task.name}`}>
                            <span
                              draggable={false}
                              onMouseDown={() => {
                                onItemSelected("Edit Task", task._id, projectId);
                              }}
                              className="tw-ml-6 tw-font-semibold hover:tw-text-gray-400 tw-cursor-pointer ellipsis"
                            >{`TK(${task._id}): ${task.name}`}</span>
                          </Tooltip>
                          {isPermission && (
                            <div
                              onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <HambugerDropdown menuItems={TASK_MENUITEMS} itemId={task._id} parentId={projectId} dropdownAlign="end" />
                            </div>
                          )}
                        </Flex>
                        <Flex vertical>
                          {task.actioners.length > 0
                            ? task.actioners.map((el) => {
                                return <span key={el._id}>{el.name}</span>;
                              })
                            : ""}
                        </Flex>
                        <Flex align="center" justify={task.task_tags.length ? "space-between" : "end"}>
                          <Flex align="center" gap={2}>
                            {task.task_tags
                              .slice(0, 2)
                              .map(
                                ({ tag_color, tag_name, task_tag_link_id }) =>
                                  tag_color && <Badge count={tag_name} color={tag_color} key={task_tag_link_id} />
                              )}
                          </Flex>
                          <Flex align="center" gap={2}>
                            <span>{task.date_deadline === null ? "" : formatDate(task.date_deadline)}</span>
                            <div
                              className="tw-cursor-pointer"
                              id="prority"
                              onMouseDown={(e) => {
                                e.stopPropagation();
                                isPermission && onChangePriority(e, task);
                              }}
                            >
                              {+task.priorityNUM ? <RiFlag2Fill color="red" /> : <RiFlag2Line />}
                            </div>
                          </Flex>
                        </Flex>
                      </Flex>
                      {task.sub_tasks?.length > 0 && (
                        <>
                          <Divider className="tw-my-2" />

                          <Flex gap={4} wrap="wrap" onClick={(e) => e.stopPropagation()}>
                            <span className="tw-font-bold">Sub-tasks:</span>
                            {task.sub_tasks.map((subTask) => (
                              <Tooltip title={`SubTK(${subTask._id}): ${subTask.name}`} key={subTask._id}>
                                <span
                                  onMouseDown={() => onItemSelected("Edit Sub-task", subTask?._id, subTask?.parent_task_id)}
                                  className={classNames("tw-font-semibold tw-cursor-pointer", {
                                    "tw-text-[#A5C6F2]": subTask?.task_actionedYN === "1"
                                  })}
                                >{`SubTK(${subTask._id});`}</span>
                              </Tooltip>
                            ))}
                          </Flex>
                        </>
                      )}
                    </Card>
                  ))}
                </GridLayout>
              </Card>
            </Flex>
          </Flex>
        ))}
      </GridLayout>
    </Flex>
  );
};
export default TaskKanban;

TaskKanban.propTypes = {
  projectTasks: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired
};
