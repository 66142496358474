import { useEffect, useState, useRef } from "react";
import { Flex } from "antd";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { FiBell } from "react-icons/fi";
import { getAlerts, postMarkAsSeenAlerts } from "API/notifications";
import { useSelector } from "react-redux";
import AlertsList from "layouts/Navbar/Notifications/AlertsList";
import useMobileWindow from "hooks/useMobileWindow";
import { useWarningModal } from "hooks/useWarningModal";

const Notifications = () => {
  const { isAlerts } = useSelector((state) => state.tasks);
  const { pathname } = useLocation();
  const isMobile = useMobileWindow();
  const { onDisplayError } = useWarningModal();

  const [allNotifications, setAllNotifications] = useState([]);
  const [unreadAlerts, setUnreadAlerts] = useState([]);
  const [seenAlerts, setSeenAlerts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isMarkAsSeenLoading, setIsMarkAsSeenLoading] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsMarkAsSeenLoading(false);
  };

  const [hasInteracted, setHasInteracted] = useState(false);
  const [isSoundAllowed, setIsSoundAllowed] = useState(false);
  const [previousAlerts, setPreviousAlerts] = useState(0);

  useEffect(() => {
    const userInteracted = localStorage.getItem("userInteracted");
    if (userInteracted === "true") {
      setHasInteracted(true);
    }
  }, []);

  const handleUserInteraction = () => {
    if (!hasInteracted) {
      setHasInteracted(true);
      setIsSoundAllowed(true);
      localStorage.setItem("userInteracted", "true");
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);

    return () => {
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
    };
  }, [isAlerts, pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    getNotificationsData();
  }, [pathname, isAlerts]);

  const getNotificationsData = async () => {
    await getAlerts()
      .then((res) => {
        if (res?.data?.error) {
          onDisplayError(res.data.error);
          return;
        }
        const data = res?.data;
        if (res.data.success === 1) {
          const unread = data.unseen_alerts;
          const unreadlength = unread?.length;
          const seen = data.last_10_seen_alerts;
          setAllNotifications([...unread, ...seen]);
          setUnreadAlerts(unread);
          setSeenAlerts(seen);
          setPreviousAlerts(unreadlength);
          const isChange = unreadlength !== previousAlerts;
          if (isChange && unread && unreadlength > 0) {
            playNotificationSound();
          }
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.location.href = "https://account2.task925.com/?killsession";
        } else onDisplayError(err.message);
      })
      .finally(() => setIsMarkAsSeenLoading(false));
  };

  const playNotificationSound = () => {
    if (isSoundAllowed) {
      const audio = new Audio("/sounds/bicycle-ring.wav");
      audio.play();
    }
  };

  const onMarkAsSeen = async (markedAlertIds) => {
    // console.log(markedAlertIds, "markedAlertIds-onMarkAsSeen");
    setIsMarkAsSeenLoading(true);
    const formData = { alertIDs: markedAlertIds };
    await postMarkAsSeenAlerts(formData)
      .then((res) => {
        console.log(res, "res-mark-as-seen");
        if (res?.data?.error) {
          onDisplayError(res?.data?.error)
          return;
        }
        getNotificationsData();
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          window.location.href = "https://account2.task925.com/?killsession";
        } else onDisplayError(err.message);
      });
  };

  return (
    <div className="tw-relative tw-cursor-pointer" ref={dropdownRef}>
      <div className="tw-relative" onClick={toggleDropdown}>
        <FiBell size={24} className="tw-text-[#3174ad]" />
        {unreadAlerts.length > 0 && (
          <Flex
            justify="center"
            align="center"
            className="tw-absolute -tw-top-0 tw-right-[2px] tw-bg-red-500 tw-w-[12px] tw-h-[12px] tw-rounded-full tw-text-white tw-text-[8px]"
          >
            <span>{unreadAlerts.length}</span>
          </Flex>
        )}
      </div>
      {isOpen && (
        <div
          className={classNames("tw-absolute tw-right-0 tw-mt-2 tw-bg-white tw-shadow-lg tw-rounded-lg tw-border tw-z-50", {
            "tw-w-[500px]": !isMobile,
            "tw-w-[300px]": isMobile
          })}
        >
          {allNotifications.length > 0 ? (
            <AlertsList
              unreadAlerts={unreadAlerts}
              seenAlerts={seenAlerts}
              onMarkAsSeen={onMarkAsSeen}
              isMarkAsSeenLoading={isMarkAsSeenLoading}
            />
          ) : (
            <Flex justify="center" align="center">
              <p className="tw-p-2 tw-text-gray-500">No new alerts</p>
            </Flex>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
